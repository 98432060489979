<template>
  <div class="index" :class="isShowRight ? 'open-right' : ''">
    <div class="left-content">
      <div class="banner" v-if="isShowBanner && !account">
        <template v-if="isWap">
          <van-swipe
            v-if="wapBannerList.length > 0"
            :autoplay="2000"
            indicator-color="white"
          >
            <van-swipe-item v-for="item in wapBannerList" :key="item.id">
              <a v-if="item.url" :href="item.url"
                ><img :src="item.imgUrl" :alt="item.name"
              /></a>
              <img v-else :src="item.imgUrl" :alt="item.name" />
            </van-swipe-item>
          </van-swipe>
          <img v-else src="@/assets/images/common/banner.png" alt="" />
          <i class="flex-v-center close-banner">
            <img
              src="@/assets/images/common/close.png"
              alt="关闭"
              @click="isShowBanner = false"
            />
          </i>
        </template>
        <template v-else>
          <el-carousel
            v-if="pcBannerList.length > 0"
            trigger="click"
            arrow="never"
          >
            <el-carousel-item v-for="item in pcBannerList" :key="item.id">
              <a v-if="item.url" :href="item.url"
                ><img :src="item.imgUrl" :alt="item.name"
              /></a>
              <img v-else :src="item.imgUrl" :alt="item.name" />
            </el-carousel-item>
          </el-carousel>
          <img v-else src="@/assets/images/common/pc/banner.png" alt="" />
          <i class="close-banner">
            <img
              src="@/assets/images/common/pc/close.png"
              alt="关闭"
              @click="isShowBanner = false"
            />
          </i>
        </template>
      </div>
      <div class="symbols">
        <div class="title is-show-large is-show-medium">
          <div class="title-info row">
            <div class="name-change large-4 medium-6 small-4 row">
              <p class="name large-6 medium-6">品种</p>
              <p class="change large-6 medium-6">变化</p>
            </div>
            <p class="sale large-3 medium-3">卖出</p>
            <p class="buy large-3 medium-3">买入</p>
            <p class="low-high large-2 is-show-large">最低价/最高价</p>
          </div>
          <i class="go-detail"></i>
        </div>
        <template v-if="symbols.length == 0">
          <!-- <div class="loading-box">
            <span class="loading-pinner">
              <svg viewBox="25 25 50 50" class="loading-circular">
                <circle cx="50" cy="50" r="20" fill="none"></circle>
              </svg>
            </span>
            <div class="loading-text">加载中...</div>
          </div> -->
          <Loading />
        </template>
        <template v-else>
          <div class="symbol" v-for="item in symbols" :key="item.name">
            <div class="symbol-info row" @click="handleLine(item.name)">
              <div class="name-change large-4 medium-6 small-4 row">
                <p class="name large-6 medium-6">
                  <span class="name-ch">{{ item.cname }}</span>
                  <span class="name-en">{{ item.name }}</span>
                </p>
                <p class="change large-6 medium-6">
                  <span class="rise" :class="item.rise > 0 ? 'up' : 'down'"
                    >{{ item.rise > 0 ? "+" : "" }}{{ item.rise }}</span
                  >
                  <span class="rise" :class="item.riseRate > 0 ? 'up' : 'down'"
                    >{{ item.riseRate > 0 ? "+" : ""
                    }}{{ item.riseRate + "%" }}</span
                  >
                </p>
              </div>
              <p class="sale large-3 medium-3 small-4">
                <span class="low-high-text" v-if="isWap"
                  >低：{{
                    NP.round(Math.min(item.low, item.b), item.p).toFixed(item.p)
                  }}</span
                >
                <span
                  class="price"
                  :class="item.bWave === 'up' ? 'up' : 'down'"
                  >{{ NP.round(item.b, item.p).toFixed(item.p) }}</span
                >
                <button
                  class="button sale-button"
                  @click.stop="handleOpenDetail(item.name, true, 1)"
                >
                  卖出
                </button>
              </p>
              <p class="buy large-3 medium-3 small-4">
                <span class="low-high-text" v-if="isWap"
                  >高：{{
                    NP.round(Math.max(item.high, item.b), item.p).toFixed(
                      item.p
                    )
                  }}</span
                >
                <span
                  class="price"
                  :class="item.aWave === 'up' ? 'up' : 'down'"
                  >{{ NP.round(item.a, item.p).toFixed(item.p) }}</span
                >
                <button
                  class="button buy-button"
                  @click.stop="handleOpenDetail(item.name, true, 0)"
                >
                  买入
                </button>
              </p>
              <p class="low-high large-2 is-show-large">
                {{
                  NP.round(Math.min(item.low, item.b), item.p).toFixed(item.p)
                }}/{{
                  NP.round(Math.max(item.high, item.b), item.p).toFixed(item.p)
                }}
              </p>
            </div>
            <i class="go-detail" @click="handleOpenDetail(item.name, false, 0)">
              <img src="@/assets/images/common/arrow.png" alt="详情" />
            </i>
          </div>
        </template>
      </div>
      <div class="kline">
        <span class="top-line"></span>
        <div id="line" class="line"></div>
      </div>
    </div>
    <transition name="slide-right">
      <div class="right-content" v-show="isShowRight">
        <Detail
          ref="detail"
          v-show="isDetail && !isWapOrder"
          @closeDetail="handleCloseDetail"
        />
      </div>
    </transition>
    <Order
      ref="order"
      v-show="isOrder && isWapOrder"
      @closeOrderInfo="handleCloseOrderInfo"
    />
  </div>
</template>

<script>
import Detail from "@/components/market/detail.vue";
import Order from "@/components/market/order.vue";
import Loading from "@/components/loading.vue";
import common from "@/apis/common.js";
export default {
  name: "Index",
  components: { Detail, Order, Loading },
  data() {
    return {
      isShowBanner: true,
      isLine: false, //是否已经加载K线
      isDetail: false, //是否显示详情
      isOrder: false, //是否下单
      orderType: 0, //下单类型 默认0买入 1卖出
      isWapOrder: false, //是否wap首页点击
      pcBannerList: [],
      wapBannerList: [],
    };
  },
  computed: {
    symbols() {
      let symbols = this.$store.getters["socket/symbols"];
      return symbols.sort(this.handleCompare("name"));
    },
    isWap() {
      return this.$store.state.common.isWap;
    },
    account() {
      return this.$store.state.login.userInfo &&
        this.$store.state.login.userInfo.account
        ? this.$store.state.login.userInfo.account
        : "";
    },
    isShowRight() {
      return (
        (this.isDetail && !this.isWapOrder) || (this.isOrder && this.isWapOrder)
      );
    },
  },
  watch: {
    account: {
      handler(n, o) {
        if (n !== o) {
          this.initChart(null);
        }
      },
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.initChart(null);
    });
    this.getBanner();
  },
  methods: {
    //点击品种信息 更新当前品种 并切换图表
    handleLine(name) {
      //当前品种!=name 设置当前品种
      if (this.$store.state.socket.currentSymbol.name != name) {
        this.$store.commit("socket/setCurrentSymbol", { name });
        this.initChart(name);
      }
    },
    //初始化图表
    initChart(name) {
      // if (!name && this.symbols.length < 1) {
      //   return;
      // }
      this.isLine = true;
      //判断当前设置的行情颜色
      let colorType = 1;
      if (this.$store.state.common.colorSet.text == "红涨绿跌") {
        colorType = 2;
      }
      let option = {
        containerID: "line", //图表容器ID
        defaultSymbol: name ? name : "GOLD", //默认产品名称
        library_path: "./static/kline/charting_library/", //文件路径
        colorType: colorType, //默认1  （1绿涨红跌 2红涨绿跌）
      };
      //console.log(option);
      this.$store.state.socket.trader.initChart(option);
    },
    handleOpenDetail(name, isOrder, orderType) {
      //判断是否登录
      if (!this.account) {
        // this.$toast({
        //   type: "fail",
        //   overlay: true,
        //   message: "请先登录",
        //   duration: 1000,
        // });
        this.$store.commit("common/setIsShowLogin", true);
        return;
      }
      this.handleLine(name);
      if (isOrder && this.isWap) {
        //wap 首页点击下单
        this.isWapOrder = true;
        this.isOrder = isOrder;
        this.$refs["order"].handleOrderTypeChange(orderType);
      } else {
        this.$refs["detail"].handleOrderType(isOrder, orderType);
        this.isDetail = true;
        this.isOrder = isOrder;
      }
    },
    //关闭详情
    handleCloseDetail() {
      this.isDetail = false;
      this.isOrder = false;
    },
    //关闭下单
    handleCloseOrderInfo() {
      this.isOrder = false;
      this.isWapOrder = false;
    },
    handleCompare(property) {
      return function (a, b) {
        try {
          let value1 = a[property].toLowerCase();
          let value2 = b[property].toLowerCase();
          if (value1 > value2) {
            return 1;
          }
          if (value2 > value1) {
            return -1;
          }
          return 0;
        } catch (error) {
          return true;
        }
      };
    },
    getBanner() {
      //appCode  暂不传渠道
      common.getActivityList({ appCode: "JF_9999" }).then((res) => {
        if (res.IsSuccess) {
          res.Data.forEach((item) => {
            if (item.type == 24) {
              //设置首页banner
              this.pcBannerList.push(item);
            }
            if (item.type == 26) {
              //设置首页banner
              this.wapBannerList.push(item);
            }
          });
          //根据位置排序
          this.pcBannerList.sort(this.handleCompare("position"));
          this.wapBannerList.sort(this.handleCompare("position"));
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/index.scss";
</style>
