import axios from "axios";
import { Toast } from "vant";
import Qs from "qs";
import $store from "@/store/index.js";
//创建axios实例
const request = axios.create({
  baseURL: "/apis", //api请求默认前缀
  timeout: 12000, //请求超时时间
  withCredentials: true,
});
// 异常拦截处理器
const errorHandler = (error) => {
  if (error.response) {
    if (error.response.status === 403) {
      //Forbidden'
      Toast({
        type: "fail",
        mask: true,
        message: "Forbidden",
      });
    }
    if (error.response.status === 500) {
      //服务器错误'
      Toast({
        type: "fail",
        mask: true,
        message: "请重试",
      });
    }
    if (error.response.status === 401) {
      //Unauthorized','Authorization verification failed'
      Toast({
        type: "fail",
        mask: true,
        message: "请重新登录",
      });
    }
  }
  return Promise.reject(error);
};

//请求拦截器
request.interceptors.request.use(
  (config) => {
    function S4() {
      return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
    }
    function guid() {
      return (
        S4() +
        S4() +
        "-" +
        S4() +
        "-" +
        S4() +
        "-" +
        S4() +
        "-" +
        S4() +
        S4() +
        S4()
      );
    }
    //config.guid = guid();
    config.headers.common["uuid"] = guid();
    // 在发送请求之前做些什么  如设置token,header,区分版本接口,正在加载中等
    if (config.url.indexOf("/cms/") > -1) {
      //cms接口
      config.baseURL = "";
    } else {
      config.data = Qs.stringify(config.data); //修改请求方式为表单提交
      config.headers["token"] = localStorage.getItem("token");
    }
    console.log(config);
    return config;
  },
  function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
  }
);

//响应拦截器
request.interceptors.response.use(
  (response) => {
    //对返回的数据做点什么 如更改数据格式
    if (
      response.data.Status == "-1" &&
      response.data.Data.indexOf("未登录") > -1
    ) {
      Toast({
        type: "fail",
        mask: true,
        message: "请重新登录",
      });
      //清除localStorage
      let clientid = localStorage.getItem("clientid");
      localStorage.clear();
      localStorage.setItem("clientid", clientid);

      //同步store
      $store.commit("login/setToken", "");
      $store.commit("login/setUserType", "");
      $store.commit("login/setUserInfo", {});
      //返回首页 更新socket链接
      location.reload();
      $store.dispatch("socket/changeSocket");
    } else {
      return response.data;
    }
  },
  errorHandler
  // function (error) {
  //   // 对响应错误做些什么
  //   return Promise.reject(error);
  // }
);

export default request;
