import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

//自动引入部分vant组件
import {
  Toast,
  Uploader,
  Popup,
  Picker,
  List,
  datetimePicker,
  Swipe,
  SwipeItem,
  Tab, 
  Tabs,
} from "vant";
//引入部分element-ui组件
import {
  Select,
  Option,
  DatePicker,
  Cascader,
  Carousel,
  CarouselItem,
} from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
//引入验证方法
import { checkParam, checkSubmit } from "@/verify/config";
Vue.config.productionTip = false;

//vant
Vue.use(Toast);
Vue.use(Uploader);
Vue.use(Popup);
Vue.use(Picker);
Vue.use(List);
Vue.use(datetimePicker);
Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(Tab);
Vue.use(Tabs);
//element-ui
Vue.use(Select);
Vue.use(Option);
Vue.use(DatePicker);
Vue.use(Cascader);
Vue.use(Carousel);
Vue.use(CarouselItem);
Vue.directive("checkParam", checkParam);
Vue.directive("checkSubmit", checkSubmit);

//引入number-precision
import NPm from "number-precision";
NPm.enableBoundaryChecking(false);
Vue.prototype.NP = {};
Vue.prototype.NP.strip = (a = 0) => {
  if (!(typeof a === "number" && !window.isNaN(a))) {
    a = 0;
  }
  return NPm.strip(a);
};
Vue.prototype.NP.plus = (a = 0, b = 0) => {
  return NPm.plus(a, b);
};
Vue.prototype.NP.minus = (a = 0, b = 0) => {
  return NPm.minus(a, b);
};
Vue.prototype.NP.times = (a = 0, b = 0) => {
  return NPm.times(a, b);
};
Vue.prototype.NP.divide = (a = 0, b = 0) => {
  return NPm.divide(a, b);
};
Vue.prototype.NP.round = function (a = 0, b = 0) {
  return NPm.round(a, b);
};

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
