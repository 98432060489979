import index from "@/views/index.vue";

// 菜单相关路由
export const menu = [
  {
    path: "/",
    name: "index",
    meta: {
      title: "行情",
      pathWay: "0",
      isShow: true,
      showType: "wap",
      icon: "market",
    },
    component: index,
  },
  {
    path: "/trade",
    name: "trade",
    meta: {
      title: "交易",
      pathWay: "1",
      isShow: true,
      showType: "wap",
      icon: "trade",
    },
    component: () =>
      import(/* webpackChunkName: "trade" */ "../views/trade/trade.vue"),
  },
  {
    path: "/fundsManage",
    meta: {
      title: "资金管理",
      isShow: true,
      pathWay: "2",
      icon: "funds",
    },
    component: () =>
      import(/* webpackChunkName: "deposit" */ "../views/common.vue"),
    children: [
      {
        path: "/deposit",
        name: "deposit",
        meta: {
          title: "充值",
          pathWay: "2-1",
          isShow: true,
        },
        component: () =>
          import(
            /* webpackChunkName: "deposit" */ "../views/funds/deposit.vue"
          ),
      },
      {
        path: "/withdraw",
        name: "withdraw",
        meta: {
          title: "取款",
          pathWay: "2-2",
          isShow: true,
        },
        component: () =>
          import(
            /* webpackChunkName: "withdraw" */ "../views/funds/withdraw.vue"
          ),
      },
      {
        path: "/fundsDetails",
        name: "fundsDetails",
        meta: {
          title: "资金明细",
          pathWay: "2-3",
          isShow: true,
        },
        component: () =>
          import(
            /* webpackChunkName: "fundsDetails" */ "../views/funds/fundsDetails.vue"
          ),
      },
      {
        path: "/bonus",
        name: "bonus",
        meta: {
          title: "美元赠金",
          pathWay: "2-4",
          isShow: true,
        },
        component: () =>
          import(
            "../views/funds/bonus.vue"
          ),
      },
    ],
  },
  {
    path: "/accountInfo",
    name: "accountInfo",
    meta: {
      title: "账户信息",
      isShow: true,
      pathWay: "3",
      icon: "user",
    },
    component: () =>
      import(/* webpackChunkName: "deposit" */ "../views/common.vue"),
    children: [
      {
        path: "/account",
        name: "account",
        meta: {
          title: "实名认证",
          pathWay: "3-1",
          isShow: true,
        },
        component: () =>
          import(
            /* webpackChunkName: "account" */ "../views/account/account.vue"
          ),
      },
      {
        path: "/bank",
        name: "bank",
        meta: {
          title: "银行卡",
          pathWay: "3-2",
          isShow: true,
        },
        component: () =>
          import(/* webpackChunkName: "bank" */ "../views/account/bank.vue"),
      },
      {
        path: "/email",
        name: "email",
        meta: {
          title: "邮箱",
          pathWay: "3-3",
          isShow: true,
        },
        component: () =>
          import(/* webpackChunkName: "email" */ "../views/account/email.vue"),
      },
    ],
  },
  {
    path: "/activity",
    name: "activity",
    meta: {
      title: "领红包",
      isShow: true,
      showType: "wap",
      pathWay: "4",
      icon: "activity",
    },
    component: () =>
      import("../views/activity.vue"),
  },
  {
    path: "/calendar",
    name: "calendar",
    meta: {
      title: "财经日历",
      isShow: true,
      showType: "wap",
      pathWay: "5",
      icon: "calendar",
    },
    component: () =>
      import("../views/calendar.vue"),
  },
  {
    path: "/set",
    name: "set",
    meta: {
      title: "设置",
      isShow: true,
      pathWay: "6",
      icon: "set",
    },
    component: () =>
      import("../views/setting/set.vue"),
  },
];

export const otherRouters = [
  {
    path: "/detail",
    name: "detail",
    component: () =>
      import(/* webpackChunkName: "deposit" */ "../views/common.vue"),
    children: [
      {
        path: "/historyTrade",
        name: "historyTrade",
        meta: {
          title: "历史盈亏",
          pathWay: "1-1",
          isShow: false,
        },
        component: () =>
          import(
            /* webpackChunkName: "detail" */ "../views/trade/historyTrade.vue"
          ),
      },
      {
        path: "/historyFunds",
        name: "historyFunds",
        meta: {
          title: "历史资金流水",
          pathWay: "1-1",
          isShow: false,
        },
        component: () =>
          import(
            /* webpackChunkName: "detail" */ "../views/trade/historyFunds.vue"
          ),
      },
    ],
  },
  {
    path: "/*",
    name: "index",
    meta: {
      title: "行情",
      pathWay: "0",
      isShow: false,
    },
    component: index,
  },
];
