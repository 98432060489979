<template>
  <transition :name="isWap ? 'slide-right' : 'slide-right'">
    <!-- <div class="msg-detail-overlay" @click.self="handleCloseMsg"> -->
    <div class="msg visible">
      <div class="back-arrow" @click="handleCloseMsg">
        <i></i>
      </div>
      <Tab
        ref="msg"
        :tabList="tabList"
        @changeTab="handleChangeTab"
        :tabIndex="tabIndex"
        :account="account"
      />
      <van-list
        class="msg-list scrollbar"
        v-model="loading"
        :offset="15"
        :finished="finished"
        @load="infinite"
        v-if="tabIndex == 1"
      >
        <template v-if="list.length > 0">
          <div class="msg-info" v-for="item in list" :key="item.messageId">
            <!-- <div class="info-top">
                <span class="info-date">{{ item.noticeTime }}</span>
                <span class="info-arrow" @click="handleInfo(item.messageId, 5)"
                  >查看详情<i></i
                ></span>
              </div>
              <div class="info-bottom" @click="handleInfo(item.messageId, 5)">
                <p class="info-type">行情提醒</p>
                <p class="info-title">
                  {{ item.title }}
                </p>
              </div> -->
            <div class="info-left" @click="handleInfo(item.messageId, 5)">
              <p class="info-type">行情提醒</p>
              <p class="info-date-title">
                <span class="info-date">{{ item.noticeTime }}</span>
                <span class="info-title">{{ item.title }}</span>
              </p>
            </div>
            <i class="msg-arrow" @click="handleInfo(item.messageId, 5)"></i>
          </div>
        </template>
        <div class="no-list" v-if="list.length == 0 && !loading">暂无内容</div>
      </van-list>
      <van-list
        class="msg-list scrollbar"
        v-model="loading"
        :offset="15"
        :finished="finished"
        @load="infinite"
        v-if="tabIndex == 2"
      >
        <template v-if="list.length > 0">
          <div class="msg-info" v-for="item in list" :key="item.messageId">
            <!-- <div class="info-top">
                <span class="info-date">{{ item.noticeTime }}</span>
                <span class="info-arrow" @click="handleInfo(item.messageId, 6)"
                  >查看详情<i></i
                ></span>
              </div>
              <div class="info-bottom" @click="handleInfo(item.messageId, 5)">
                <p class="info-type">系统消息</p>
                <p class="info-title">
                  {{ item.title }}
                </p>
              </div> -->
            <div class="info-left" @click="handleInfo(item.messageId, 6)">
              <p class="info-type">系统消息</p>
              <p class="info-date-title">
                <span class="info-date">{{ item.noticeTime }}</span>
                <span class="info-title">{{ item.title }}</span>
              </p>
            </div>
            <i class="msg-arrow" @click="handleInfo(item.messageId, 6)"></i>
          </div>
        </template>
        <div class="no-list" v-if="list.length == 0 && !loading">暂无内容</div>
      </van-list>
      <van-list
        class="msg-list scrollbar"
        v-model="loading"
        :offset="15"
        :finished="finished"
        @load="infinite"
        v-if="tabIndex == 3"
      >
        <template v-if="list.length > 0">
          <div class="msg-info" v-for="item in list" :key="item.messageId">
            <!-- <div class="info-top">
                <span class="info-date">{{ item.noticeTime }}</span>
                <span class="info-arrow" @click="handleInfo(item.messageId, 4)"
                  >查看详情<i></i
                ></span>
              </div>
              <div class="info-bottom" @click="handleInfo(item.messageId, 5)">
                <p class="info-type">站内信</p>
                <p class="info-title">
                  {{ item.title }}
                </p>
              </div> -->
            <div class="info-left" @click="handleInfo(item.messageId, 4)">
              <p class="info-type">站内信</p>
              <p class="info-date-title">
                <span class="info-date">{{ item.noticeTime }}</span>
                <span class="info-title">{{ item.title }}</span>
              </p>
            </div>
            <i class="msg-arrow" @click="handleInfo(item.messageId, 4)"></i>
          </div>
        </template>
        <div class="no-list" v-if="list.length == 0 && !loading">暂无内容</div>
      </van-list>
      <MsgDetail
        ref="msgDetail"
        v-show="isShowInfo"
        :isWap="isWap"
        @closeInfo="handleCloseInfo"
      />
    </div>
    <!-- </div> -->
  </transition>
</template>

<script>
import Tab from "@/components/tab.vue";
import MsgDetail from "@/components/msgDetail.vue";
import msg from "@/apis/msg.js";
export default {
  name: "Msg",
  components: {
    Tab,
    MsgDetail,
  },
  data() {
    return {
      tabList: [
        {
          label: "行情资讯",
          value: "1",
        },
        {
          label: "系统消息",
          value: "2",
        },
        {
          label: "站内信",
          value: "3",
        },
      ],
      tabIndex: 1,
      list: [],
      pageSize: 8,
      pageNo: 1,
      loading: false,
      finished: false,
      isShowInfo: false,
    };
  },
  props: {
    account: String,
  },
  computed: {
    isWap() {
      return this.$store.state.common.isWap;
    },
  },
  methods: {
    handleCloseMsg() {
      this.$emit("closeMsg");
    },
    // 无限加载数据
    infinite(val) {
      if (val == "init") {
        //设置tab下划线
        console.log("init");
        setTimeout(() => {
          this.$refs["msg"].setTabline(0);
        }, 300);
      }
      this.loading = true;
      this.getData();
    },
    // 切换顶部出入金
    handleChangeTab(index) {
      this.tabIndex = index;
      this.list = [];
      this.pageNo = 1;
      this.loading = false;
      this.finished = false;
      this.infinite();
    },
    // 查询数据
    getData() {
      let type = "";
      if (this.tabIndex == 1) {
        type = 5;
      } else if (this.tabIndex == 2) {
        type = 6;
      } else if (this.tabIndex == 3) {
        type = 4;
      }
      let params = {
        pageSize: this.pageSize,
        pageNo: this.pageNo,
        type: type,
      };
      this.getList(params);
    },
    getList(params) {
      msg.getList(params).then((res) => {
        if (res.IsSuccess) {
          //console.log(res.Data);
          this.loading = false;
          this.pageNo += 1;
          if (res.Data.list.length == 0) {
            this.finished = true;
            return;
          }
          this.list.push(...res.Data.list);
          if (this.list.length >= res.Data.count) {
            this.finished = true;
          }
        } else {
          this.finished = true;
          this.loading = false;
        }
      });
    },
    handleInfo(id, type) {
      this.isShowInfo = true;
      this.$refs["msgDetail"].getDetail(id, type);
      // let query = {
      //   id,
      //   type,
      // };
      // this.$router.push({
      //   name: "msgDetail",
      //   query: JSON.parse(JSON.stringify(query)),
      // });
      //this.handleCloseMsg();
    },
    handleCloseInfo() {
      this.isShowInfo = false;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/assets/scss/common/msg.scss";
</style>
