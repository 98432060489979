<template>
  <div class="login register">
    <!-- <div class="mask" v-if="isShow"></div> -->
    <div class="login-div">
      <img
        src="@/assets/images/login/close.png"
        alt=""
        class="login-close"
        @click="handleCloseRegister"
      />
      <h3 class="login-title">注册账户</h3>
      <div class="form login-form">
        <div class="input">
          <div class="input-content">
            <i class="phone"></i>
            <input
              type="text"
              placeholder="请输入手机号"
              form="rules"
              v-model="reg.phone"
              v-checkParam="rules.phone"
            />
          </div>
        </div>
        <div class="input">
          <div class="input-content">
            <i class="code"></i>
            <input
              type="text"
              placeholder="请输入验证码"
              form="rules"
              v-model="reg.code"
              v-checkParam="rules.code"
            />
            <a class="btn-send" @click="handleSendCode">{{ codeText }}</a>
          </div>
        </div>
        <div class="input">
          <div class="input-content">
            <i class="pass"></i>
            <input
              :type="isShowPass ? 'text' : 'password'"
              placeholder="请设置密码"
              form="rules"
              v-model="reg.password"
              v-checkParam="rules.password"
            />
            <i
              class="eye"
              @click="isShowPass = !isShowPass"
              :class="isShowPass ? 'eye-open' : 'eye-close'"
            ></i>
          </div>
        </div>
        <p class="agreement">
          <input type="checkbox" v-model="isChecked" />
          注册即表示您同意
          <a
            :href="accountUrl + '/register/privacy_policy.html'"
            target="_blank"
            >《用户隐私协议》</a
          >和
          <a :href="accountUrl + '/register/agreement.html'" target="_blank"
            >《客户协议》</a
          >
        </p>
        <div class="btn-div">
          <button
            class="button reg-btn"
            v-checkSubmit="'rules'"
            :submit="handleRegister"
            :disabled="isDisabled"
          >
            注册
          </button>
          <button class="button login-btn" @click="handleShowLogin">
            登录
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import login from "@/apis/login.js";
import config from "@/config/index.js";
export default {
  name: "Register",
  props: {
    isShow: Boolean,
    appId: String,
    clientid: String,
  },
  data() {
    return {
      isChecked: false,
      timer: "",
      codeText: "获取验证码",
      isShowPass: false,
      isDisabled: false,
      reg: {
        phone: "",
        password: "",
        code: "",
      },
      rules: {
        phone: [
          { rule: "required", msg: "请输入手机号" },
          { rule: /^1[3-9]\d{9}$/, msg: "请输入正确的手机号" },
        ],
        code: [
          { rule: "required", msg: "请输入验证码" },
          { rule: /(\d{4,6})/, msg: "验证码格式错误" },
        ],
        password: [
          { rule: "required", msg: "密码不能为空" },
          {
            rule: /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,12}$/,
            msg: "密码为6-12位字母加数字",
          },
        ],
      },
      accountUrl: config.BASE_ACCOUNT_URL,
    };
  },
  methods: {
    handleCloseRegister() {
      this.$emit("closeRegister");
    },
    handleShowLogin() {
      this.$emit("showLogin");
    },
    handleRegister() {
      if (!this.isChecked) {
        this.$toast("您还没有确认协议");
        return;
      }
      if (this.isDisabled) {
        return;
      }
      this.isDisabled = true;
      this.$toast.loading({
        duration: 0,
        mask: true,
        message: "提交中...",
      });
      const params = {
        mobile: this.reg.phone,
        code: this.reg.code,
        pwd: this.reg.password,
        appcode: "", //app包编码,如开户来源是app,必填,否则为空
        agentId: "", //推广码
        type: 2, //1一键开户/登录;2手机号;3邮箱
        issueCountry: 1, //默认大陆用户  中国大陆1,中国香港2,中国澳门3,中国台湾4,其他国家及地区5
        fromType: 2, //1 pc, 2 wap, 3 ios, 4 android
        clientid: this.clientid,
        channelType: "1", //1:etb贵金属
      };
      login.realRegister(params).then((res) => {
        this.$toast.clear();
        if (res.IsSuccess) {
          this.$toast.success("恭喜您，注册成功");
          //隐藏登录表单
          this.$emit("closeRegister");
          //注册成功自动登录 存储信息
          localStorage.setItem("token", res.Token);
          localStorage.setItem("userType", res.Data.type); //真实or模拟用户 1真实 0模拟
          //同步store
          this.$store.commit("login/setToken", res.Token);
          this.$store.commit("login/setUserType", res.Data.type);
          //获取用户信息
          this.$store.dispatch("login/getUserInfo", { password: params.pwd });
          // 登录成功连接socket
          this.$store.dispatch("socket/changeSocket", {
            account: res.Data.account,
            pwd: params.pwd,
          });
        } else {
          this.isDisabled = false;
          this.$toast(res.Data);
        }
      });
    },
    handleSendCode() {
      //手机是否填写
      if (!this.reg.phone || !/^1[3-9]\d{9}$/.test(this.reg.phone)) {
        this.$toast("请输入正确的手机号");
        return;
      }
      //显示腾讯验证
      var captcha = new window.TencentCaptcha(this.appId, (res) => {
        if (res.ret === 0) {
          // 滑块验证通过---获取验证码--执行倒计时
          this.doSendCode(res);
        } else {
          this.$toast("请先完成验证");
        }
      });
      // 显示验证码
      captcha.show();
    },
    //发送验证码
    doSendCode(data) {
      const params = {
        ticket: data.ticket,
        randstr: data.randstr,
        mobile: this.reg.phone,
        type: 0, //类型（0腾讯云，1阿里云）
      };
      login.sendSms(params).then((res) => {
        if (res.IsSuccess) {
          this.$toast(res.Data);
          this.countNum();
        } else {
          this.$toast(res.Data);
        }
      });
    },
    //发送验证码倒计时
    countNum() {
      clearInterval(this.timer);
      let count = 60;
      let text = "";
      this.timer = setInterval(() => {
        if (count <= 0) {
          count = 0;
          text = "重新获取验证码";
          clearInterval(this.timer);
        } else {
          count -= 1;
          text = count + "秒后重新获取";
        }
        this.codeText = text;
      }, 1000);
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/common/login.scss";
</style>
