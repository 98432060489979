<template>
  <transition :name="isWap ? 'slide-right' : ''">
    <!-- <div class="detail-overlay" @click.self="handleClose"> -->
    <div class="msg-detail">
      <div class="msg-back" @click="handleClose">
        <i></i>
        <span>返回</span>
      </div>
      <div class="msg-content scrollbar">
        <p class="title">{{ info.title }}</p>
        <p class="date">{{ info.noticeTime }}</p>
        <div class="info" v-html="info.content"></div>
      </div>
    </div>
    <!-- </div> -->
  </transition>
</template>

<script>
import msg from "@/apis/msg.js";
export default {
  name: "MsgDetail",
  props: {
    isWap: Boolean,
  },
  data() {
    return {
      info: {},
    };
  },
  methods: {
    getDetail(id, type) {
      let params = {
        messageId: id,
        type: type,
      };
      msg.getInfo(params).then((res) => {
        if (res.IsSuccess) {
          this.info = res.Data.siteMesasge;
        }
      });
    },
    handleClose() {
      this.$emit("closeInfo");
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/assets/scss/common/msg.scss";
</style>
