<template>
  <div class="loading-box">
    <span class="loading-pinner">
      <svg viewBox="25 25 50 50" class="loading-circular">
        <circle cx="50" cy="50" r="20" fill="none"></circle>
      </svg>
    </span>
    <div class="loading-text">{{ loadingText }}</div>
  </div>
</template>

<script>
export default {
  name: "Loading",
  props: {
    loadingText: {
      type: String,
      default: "加载中...",
    },
  },
};
</script>

<style></style>
