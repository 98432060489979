import symbols from "@/config/symbols.js";
import {
  unique,
  foreignCovertProfit,
  indexCovertProfit,
} from "@/utils/socketCommon.js";
import NP from "number-precision";
NP.enableBoundaryChecking(false);
const state = {
  trader: null, //websocketet 实例
  liveData: {}, //websocket返回的实时数据
  tradeList: [], //websocket返回的持仓列表
  wsLoginInfo: {}, //实时的资金情况
  symbolList: [], //所有产品的信息，只返回一次
  socketStatus: {}, //websocketet登录状态
  tabType: 1, //交易类型 1、市价   2、挂单
  currentSymbol: {}, //当前选择的品种信息
  payLoading: false, //防止下单的时候意外退出  重复下单  在store中存储
  modifyLoading: false, //防止修改订单的时候意外退出  重复修改  在store中存储
  historyTradeList: [], //历史交易记录
  symbolGroup: {}, //分组信息
  timeZone: {}, //时区信息
};

const getters = {
  // 品种信息
  symbols(state) {
    //首页显示的品种
    let showSymbols = Object.keys(symbols.goodsSymbolsList);
    return state.symbolList.filter((item) => {
      if (item.name != "US0il" && showSymbols.includes(item.name)) {
        //item["cname"] = symbols.goodsSymbolsList[item.name]; //中文名称
        item["rise"] = NP.minus(item.b, item.open).toFixed(item.p); //涨跌值=卖价-开盘价
        item["riseRate"] =
          item.open && item.open != 0
            ? NP.round(
                NP.divide(
                  NP.times(NP.minus(item.b, item.open), 100),
                  item.open
                ),
                2
              )
            : 0.0; //涨跌百分比  （卖价-开盘价）*100/开盘价 保留两位小数
        return item;
      }
    });
  },
};

const mutations = {
  //挂单和市价切换
  setTabType(state, data) {
    state.tabType = data;
  },
  //下单状态
  setPayLoading(state, data) {
    state.payLoading = data;
  },
  //需改订单状态
  setModifyLoading(state, data) {
    state.modifyLoading = data;
  },
  //设置当前品种
  setCurrentSymbol(state, params) {
    let data = state.symbolList.find((item) => item.name === params.name);
    if (data) {
      data["rise"] = data.open
        ? NP.minus(data.b, data.open).toFixed(data.p)
        : NP.round(data.b, data.p).toFixed(data.p); //卖价-开盘价
      data["riseRate"] =
        data.open && data.open != 0
          ? NP.round(
              NP.divide(NP.times(NP.minus(data.b, data.open), 100), data.open),
              2
            )
          : 0.0; //涨跌百分比  （卖价-开盘价）*100/开盘价 保留两位小数
      //data["cname"] = params.cname;
      //console.log(data);
      state.currentSymbol = data;
    } else {
      state.currentSymbol = "";
    }
  },
  //socket实例
  setTrader(state, data) {
    state.trader = data;
  },
  //websocket返回的实时数据
  setLiveData(state, data) {
    //console.log(data)
    //state.liveData = data  根据返回的实时数据 更新品种信息
    let symbol = state.symbolList.find((item) => item.name === data.n) || {};
    if (symbol.a) {
      //console.log(NP.minus(data.a, symbol.a))
      if (NP.minus(data.a, symbol.a) > 0) {
        data.aWave = "up";
      } else if (NP.minus(data.a, symbol.a) < 0) {
        data.aWave = "down";
      } else {
        data.aWave = symbol.aWave;
      }
    }
    if (symbol.b) {
      if (NP.minus(data.b, symbol.b) > 0) {
        data.bWave = "up";
      } else if (NP.minus(data.b, symbol.b) < 0) {
        data.bWave = "down";
      } else {
        data.bWave = symbol.bWave;
      }
    }
    Object.assign(symbol, data);
  },
  //websocket返回的持仓列表（持仓和挂单)
  setTradeList(state, params) {
    if (!params.data) {
      state.tradeList = [];
      return;
    }
    let keyList = [
      ...Object.keys(symbols.indexSymbolsList),
      ...Object.keys(symbols.foreignSymbolsList),
      ...Object.keys(symbols.goodsSymbolsList),
    ];
    let objList = {
      ...symbols.goodsSymbolsList,
      ...symbols.foreignSymbolsList,
      ...symbols.indexSymbolsList,
    };
    params.data.forEach((item) => {
      if (keyList.includes(item.s)) {
        item["cname"] = objList[item.s];
      }
    });
    state.tradeList = params.data;
  },
  //websocket返回的历史交易列表
  setHistoryTradeList(state, data) {
    state.historyTradeList = data;
  },
  //websocket返回的实时的资金情况
  setWsLoginInfo(state, data) {
    state.wsLoginInfo = data;
  },
  //websocket返回的所有产品的信息
  setSymbolList(state, data) {
    let symbolList = [];
    if (state.symbolList.length === 0) {
      symbolList = data;
    } else {
      symbolList = [...state.symbolList, ...data];
      symbolList = unique(symbolList); //去除重复的
    }
    //设置中文名称
    let objList = {
      ...symbols.goodsSymbolsList,
      ...symbols.foreignSymbolsList,
      ...symbols.indexSymbolsList,
    };
    symbolList.forEach((symbol) => {
      symbol["cname"] = objList[symbol.name];
    });
    state.symbolList = symbolList;
  },
  setSocketStatus(state, data) {
    state.socketStatus = data;
  },
  setSymbolGroup(state, data) {
    state.symbolGroup = data;
  },
  //时区信息
  setTimeZone(state, data) {
    state.timeZone = data;
  },
};

const actions = {
  //连接socket
  initSocket(
    { state, commit, getters, dispatch, rootState },
    params = { isInit: 1 }
  ) {
    if (!state.trader) {
      const trader = new window.Trader({
        socketK: 1, // 连接 websocketet使用的 K,Number
        version: "3.0.6", // 版本号
      });
      commit("setTrader", trader);
    }
    let socketParams = {};
    if (localStorage.getItem("token") && params.account) {
      socketParams = {
        status: rootState.login.userType == 1 ? 3 : 2, // 登录状态  1：未登录，2:模拟账户，3：真实账户
        account: params.account, //账号,如果是未登录，不需要账号密码20000008
        password: params.pwd, //密码123qwe
        //device: systemInfo.system + " " + systemInfo.model, // 系统  如："iOS 13.2.3 iphone"
        //phone: systemInfo.model, //'iphone'/ 'android'
      };
    } else {
      socketParams = {
        status: 1, // 登录状态  1：未登录，2:模拟账户，3：真实账户
        account: "", //账号,如果是未登录，不需要账号密码20000008
        password: "", //密码123qwe
        //device: systemInfo.system + " " + systemInfo.model, // 系统  如："iOS 13.2.3 iphone"
        //phone: systemInfo.model, //'iphone'/ 'android'
      };
    }
    function handleLiveData(data) {
      //更新品种实时数据
      commit("setLiveData", data);
      //计算用户实时盈亏数据
      dispatch("handleLiveProfit");
      //计算净值 和 占用保证金
      dispatch("handleUpdateUserInfo");
    }
    function handleTradeList(data) {
      //console.log(data);
      //更新订单信息
      commit("setTradeList", { data, getters });
      //计算用户实时盈亏数据
      dispatch("handleLiveProfit");
      //计算净值 和 占用保证金
      dispatch("handleUpdateUserInfo");
    }
    function handleUserInfo(data) {
      //console.log(data)
      //更新用户信息
      commit("setWsLoginInfo", data);
      //计算用户实时盈亏数据
      dispatch("handleLiveProfit");
      //计算净值 和 占用保证金
      dispatch("handleUpdateUserInfo");
    }
    function handleSymbolList(data) {
      //更新所有品种信息
      commit("setSymbolList", data);
      //计算用户实时盈亏数据
      dispatch("handleLiveProfit");
      //计算净值 和 占用保证金
      dispatch("handleUpdateUserInfo");
    }
    function handleSocketStatus(data) {
      //设置socket状态
      commit("setSocketStatus", data);
    }
    function handleSymbolGroup(data) {
      //更新组信息
      commit("setSymbolGroup", data);
    }
    function handleTimeZone(data) {
      //更新时区
      commit("setTimeZone", data);
    }
    let socketDataCB = {
      liveDataCB: handleLiveData, //fn 实时数据回调函数 {n:'',a:'',b:'',t:}   {a:买价,b:卖价,n:产品名称,t:时间(s)}
      tradeListCB: handleTradeList, //fn 持仓列表数据回调 [{}]
      userInfoCB: handleUserInfo, //fn  账户信息
      symbolInfoCB: handleSymbolList, //fn  产品信息
      loginCB: handleSocketStatus, //fn websocketet登录状态
      symbolTypeCB: handleSymbolGroup, //fn 产品组别信息
      timeZoneCB: handleTimeZone, //fn 时区信息
    };
    if (params.isInit) {
      state.trader.initSocket(socketParams, socketDataCB);
    } else {
      state.trader.changeSocket(socketParams, socketDataCB);
    }
    //更换socket连接时 把mt用户信息，持仓列表，历史交易列表重置为空（此时不返回用户信息）
    commit("setWsLoginInfo", {});
    commit("setTradeList", []);
    commit("setWsLoginInfo", {});
  },
  //更换socket 连接
  changeSocket({ dispatch }, params = {}) {
    params["isInit"] = 0;
    dispatch("initSocket", params);
  },
  //获取历史交易记录
  handleHistoryTradeList({ commit }, params) {
    function handleHistoryOrderCb(data) {
      //console.log(data);
      let keyList = [
        ...Object.keys(symbols.indexSymbolsList),
        ...Object.keys(symbols.foreignSymbolsList),
        ...Object.keys(symbols.goodsSymbolsList),
      ];
      let objList = {
        ...symbols.goodsSymbolsList,
        ...symbols.foreignSymbolsList,
        ...symbols.indexSymbolsList,
      };
      let result = [];
      data.length &&
        data.forEach((item) => {
          if (item.s !== " " && keyList.includes(item.s)) {
            item["cname"] = objList[item.s];
            result.push(item);
          }
        });
      commit("setHistoryTradeList", result);
    }
    //console.log(params)
    state.trader.getHistoryOrder(params, handleHistoryOrderCb);
  },
  //更新用户实时总盈亏（计算保证金比例时用）
  handleLiveProfit({ state, commit }) {
    let list = Object.assign([], state.tradeList); // 持仓单(包含挂单)
    let pr = 0; //总盈亏
    let profit = 0;
    list.length &&
      list.forEach((item) => {
        let symbol = state.symbolList.find((items) => items.name === item.s); //获取当前订单的商品信息
        //console.log(symbol)
        //item.cname = symbol.cname  //名称
        item.Bid = parseFloat(symbol.b).toFixed(symbol.p); //卖出价
        item.Ask = parseFloat(symbol.a).toFixed(symbol.p); //买入价

        // 盈亏计算公式：0 买入： （实时报价Bid-建仓价格）*手数*合约单位； 1 卖出：（建仓价格-实时报价）*手数*合约单位
        if (item.c === 1) {
          profit = (item.f - item.Ask) * Number(item.v / 100) * symbol.z; //当前持仓单的盈亏值（不包含手续费和库存费）(开仓价-当前买价)*手数*合约单位
        } else {
          profit = (item.Bid - item.f) * Number(item.v / 100) * symbol.z; //当前持仓单的盈亏值（不包含手续费和库存费）(当前卖价-开仓价)*手数*合约单位
        }

        // // 外汇品种二次计算浮动盈亏
        if (
          symbols.foreignSymbolsList[item.s] &&
          item.s.substring(3, 6) !== "USD"
        ) {
          profit = foreignCovertProfit(symbol, item.c, profit);
        }
        // 指数品种二次计算浮动盈亏
        if (symbols.indexSymbolsList[item.s]) {
          profit = indexCovertProfit(symbol, item.c, profit);
        }
        item.yk = profit.toFixed(2) || 0.0;

        //计算总盈亏需要去除挂单
        if (item.c === 0 || item.c === 1) {
          //console.log(item.q)
          //console.log(profit)
          pr +=
            (profit ? profit.toFixed(2) : 0) * 1 +
            ((item.q ? item.q : 0) + (item.k ? item.k : 0)) * 1;
        }
      });
    let info = Object.assign({}, state.wsLoginInfo);
    info.pr = pr; //总盈亏
    commit("setWsLoginInfo", info);
  },
  //更新用户信息(保证金比例  自己计算占用保证金)
  // handleUpdateUserInfo({ state, commit }) {
  //     // //计算持仓单  占用保证金总和 (同品种算手数多的一边, 买卖手数一样，算价格高的这边)
  //     let list = Object.assign([], state.tradeList) // 持仓单(包含挂单)
  //     //console.log(state.tradeList)
  //     let symbolList = [];  //按品种分类后的订单信息
  //     //let fee = 0; //手续费
  //     list.length && list.forEach(item => {
  //         if (item.c === 0 || item.c === 1) {  //仅获取持仓单 不包含挂单
  //             //按品种分类
  //             if(!symbolList[item.s]){
  //                 symbolList[item.s] = [];
  //             }
  //             symbolList[item.s].push(item);
  //         }
  //     })
  //     //根据品种计算保证金    占/已用保证金，可用保证金，净值，保证金比例自己计算
  //     let margin = 0; //总保证金
  //     if(Object.keys(symbolList).length > 0){
  //         margin = calculationMargin(symbolList)
  //         //console.log(margin, 400)
  //     }

  //     let userMT4Info = Object.assign({}, state.wsLoginInfo)
  //     //占用总保证金
  //     userMT4Info.zbzj = margin
  //     //计算净值=余额+信用额+盈亏(手续费已包含在盈亏中)
  //     userMT4Info.jz  = userMT4Info.b + userMT4Info.c + userMT4Info.pr
  //     //计算可用保证金=净值-占用保证金
  //     userMT4Info.kybzj = (userMT4Info.jz * 1 - userMT4Info.zbzj * 1).toFixed(2)
  //     //计算保证金比例
  //     userMT4Info.bzjbl = (userMT4Info.zbzj * 1 ? ((userMT4Info.jz / userMT4Info.zbzj) * 100).toFixed(2) : 0) + '%'
  //     //console.log(userMT4Info.jz, '净值')
  //     //console.log(userMT4Info.b, userMT4Info.pr, userMT4Info.b+userMT4Info.pr)
  //     //console.log(userMT4Info.zbzj, 'zbzj')
  //     //console.log(userMT4Info.bzjbl, 'bzjbl')
  //     commit('setWsLoginInfo', userMT4Info)
  // },
  //更新用户信息(保证金比例   占用保证金用mt4返回值  暂不自己计算)
  handleUpdateUserInfo({ state, commit }) {
    let userMT4Info = Object.assign({}, state.wsLoginInfo);
    //占用总保证金
    userMT4Info.zbzj = userMT4Info.m;
    //计算净值=余额+信用额+盈亏(手续费已包含在盈亏中)
    userMT4Info.jz = userMT4Info.b + userMT4Info.c + userMT4Info.pr;
    //计算可用保证金=净值-占用保证金
    userMT4Info.kybzj = (userMT4Info.jz * 1 - userMT4Info.zbzj * 1).toFixed(2);
    //计算保证金比例
    userMT4Info.bzjbl =
      (userMT4Info.zbzj * 1
        ? ((userMT4Info.jz / userMT4Info.zbzj) * 100).toFixed(2)
        : 0) + "%";
    //console.log(userMT4Info.jz, '净值')
    //console.log(userMT4Info.b, userMT4Info.pr, userMT4Info.b+userMT4Info.pr)
    //console.log(userMT4Info.zbzj, 'zbzj')
    //console.log(userMT4Info.bzjbl, 'bzjbl')
    commit("setWsLoginInfo", userMT4Info);
  },
};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
