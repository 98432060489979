<template>
  <div class="tab">
    <p
      class="tab-name"
      @click="handleTab"
      v-for="item in tabList"
      :data-value="item.value"
      :key="item.value"
      :class="tabIndex == item.value ? 'act' : ''"
    >
      {{ item.label }}
    </p>
    <div class="tab-line"></div>
  </div>
</template>

<script>
export default {
  name: "Tab",
  data() {
    return {};
  },
  props: {
    tabList: Array,
    tabIndex: Number,
    account: String,
  },
  methods: {
    handleTab(e) {
      let val = parseInt(e.target.attributes["data-value"].value);
      //如果是msg判断点击站内信时是否登录
      if (this.$parent.$refs["msg"] && val == 3 && !this.account) {
        // this.$toast.fail({
        //   overlay: true,
        //   message: "请先登录",
        //   duration: 1000,
        // });
        this.$store.commit("common/setIsShowLogin", true);
        return false;
      }
      //获取当前点击元素的值
      //this.tabIndex = e.target.attributes["data-value"].value;
      //获取同级最后一个元素，设置样式
      this.setTranslateX(
        e.target.parentNode.children,
        e.target.offsetLeft + e.target.clientWidth / 2
      );
      this.$emit("changeTab", val);
    },
    // x  tab中下划线偏移值
    setTranslateX($node, x) {
      $node[
        $node.length - 1
      ].style.transform = `translateX(${x}px) translateX(-50%)`;
    },
    setTabline(index) {
      let $node = document.getElementsByClassName("tab")[index].children;
      let x =
        $node[this.tabIndex - 1].offsetLeft +
        $node[this.tabIndex - 1].clientWidth / 2;

      this.setTranslateX($node, x);
    },
  },
  mounted() {
    //设置当前选中的tab下划线
    if (!this.$parent.$refs["msg"] && !this.$parent.$refs["orderTab"]) {
      this.setTabline(1);
      // let $node = document.getElementsByClassName("tab")[1].children;
      // let x =
      //   $node[this.tabIndex - 1].offsetLeft +
      //   $node[this.tabIndex - 1].clientWidth / 2;
      // this.setTranslateX($node, x);
    }
    if (this.$parent.$refs["msg"]) {
      this.setTabline(0);
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/assets/scss/common/dialog.scss";
</style>
