import axios from "@/axios/axios.js";
const login = {
  // 登录
  async doLogin(params) {
    return await axios.post("/zk/login", params, {
      des: "直客wap登录",
      sTime: new Date().getTime(),
    });
  },
  // 获取用户信息
  async getUserInfo(params) {
    return await axios.post("/uc/user/userinfo/identityInfo", params, {
      des: "直客wap获取用户信息",
      sTime: new Date().getTime(),
    });
  },
  // 腾讯验证码
  async getValidateAppId(params) {
    return await axios.post(
      "/validateAppId",
      { params },
      { des: "直客wap腾讯验证码", sTime: new Date().getTime() }
    );
  },
  // 获取验证码
  async sendSms(params) {
    return await axios.post("/validateSendcode", params, {
      des: "直客wap获取验证码",
      sTime: new Date().getTime(),
    });
  },
  // 忘记密码，获取验证码
  async sendSms2(params) {
    return await axios.post("/uc/sendcode", params, {
      des: "直客wap忘记密码，获取验证码",
      sTime: new Date().getTime(),
    });
  },
  // 忘记密码，修改密码
  async rePwd(params) {
    return await axios.post("/uc/user/password/modify", params, {
      des: "直客wap忘记密码，修改密码",
      sTime: new Date().getTime(),
    });
  },
  //找回密码
  async submitNewPwd(params) {
    return await axios.post("/web/account/password", params, {
      des: "直客wap修改密码",
      sTime: new Date().getTime(),
    });
  },
  //注册接口
  async realRegister(params) {
    return await axios.post("/web/account/register", params, {
      des: "直客wap真实账户注册接口",
      sTime: new Date().getTime(),
    });
  },
  //登出接口
  async logout(params) {
    return await axios.post("/uc/zk/logout", params, {
      des: "直客wap登出接口",
      sTime: new Date().getTime(),
    });
  },
  //真实账户验证数据是否重复
  // async realUserData(params, option) {
  //   return await axios.post('/web/realaccount/validate', params, option)
  // },
};

export default login;
