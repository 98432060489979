import axios from "@/axios/axios.js";

const msg = {
  //获取资讯
  async getList(params = {}) {
    return await axios.post("/uc/user/notice/list", params, {
      des: "直客获取资讯",
      sTime: new Date().getTime(),
    });
  },
  async getInfo(params = {}) {
    return await axios.post("/uc/user/notice/detail", params, {
      des: "直客获取资讯详情",
      sTime: new Date().getTime(),
    });
  },
};

export default msg;
