function showError(msg, el) {
  let div = document.createElement("div");
  div.classList.add("input-error");
  div.innerHTML = msg;
  el.parentNode.parentNode.appendChild(div);
}

function hideError(el) {
  if (
    el.parentNode.parentNode.getElementsByClassName("input-error") &&
    el.parentNode.parentNode.getElementsByClassName("input-error")[0]
  ) {
    el.parentNode.parentNode.getElementsByClassName("input-error")[0].remove();
  }
}

const checkParam = {
  inserted: function (el, binding) {
    //console.log(el);
    el.onblur = function () {
      if (!event.keyCode) {
        //先移除提示
        hideError(el);
        for (const item of binding.value) {
          // 自定义函数验证
          if (typeof item === "function") {
            //console.log(el);
            item(el.value, (msg) => {
              showError(msg, el);
            });
            return;
          }
          let itemRule = item.rule;
          let itemMsg = item.msg;
          // 默认必填验证
          if (itemRule == "required") {
            if (!el.value || el.value === "") {
              showError(itemMsg, el);
              return;
            }
          } else if (/min/.test(itemRule)) {
            // 最小验证
            let valArr = itemRule.split(":");
            if (+el.value < +valArr[1]) {
              showError(itemMsg, el);
              return;
            }
          } else if (/max/.test(itemRule)) {
            // 最大验证
            let valArr = itemRule.split(":");
            if (+el.value > +valArr[1]) {
              showError(itemMsg, el);
              return;
            }
          } else {
            // 正则验证
            let reg = new RegExp(itemRule).test(el.value);
            if (!reg) {
              showError(itemMsg, el);
              return;
            }
          }
        }
      }
    };
  },
};

const checkSubmit = {
  inserted: function (el, binding, vNode) {
    el.onclick = function () {
      let obj = el.parentNode.parentNode;
      let elements = obj.getElementsByTagName("input");

      let evObj = document.createEvent("Event");
      evObj.initEvent("blur", true, true);
      for (let element of elements) {
        if (element.getAttribute("form") === binding.value) {
          element.dispatchEvent(evObj);
          let errorInputs = obj.getElementsByClassName("input-error");
          if (errorInputs.length !== 0) {
            return;
          }
        }
      }
      vNode.data.attrs.submit();
    };
  },
};

export { checkParam, checkSubmit };
