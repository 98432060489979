<template>
  <!-- <transition name="fade"> -->
  <div class="login">
    <!-- <div class="mask" v-if="isShow"></div> -->
    <div class="login-div">
      <img
        src="@/assets/images/login/close.png"
        alt=""
        class="login-close"
        @click="handleCloseLogin"
      />
      <h3 class="login-title">登录账户</h3>
      <div class="form login-form rules">
        <div class="input">
          <div class="input-content">
            <i class="phone"></i>
            <input
              type="text"
              placeholder="请输入手机号或账号"
              form="rules"
              v-model="login.phone"
              v-checkParam="rules.phone"
            />
          </div>
        </div>
        <div class="input">
          <div class="input-content">
            <i class="pass"></i>
            <input
              type="password"
              placeholder="请输入密码"
              form="rules"
              v-model="login.password"
              v-checkParam="rules.password"
            />
          </div>
        </div>
        <p class="forget-pass">
          <span @click="handleShowForgetPass">忘记密码</span>
        </p>
        <div class="btn-div">
          <button
            class="button login-btn"
            v-checkSubmit="'rules'"
            :submit="handleLogin"
          >
            登录
          </button>
          <button class="button reg-btn" @click="handleShowRegister">
            注册
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- </transition> -->
</template>

<script>
import login from "@/apis/login.js";
export default {
  name: "Login",
  props: {
    appId: String,
    clientid: String,
  },
  data() {
    return {
      login: {
        phone: "",
        password: "",
      },
      rules: {
        phone: [
          { rule: "required", msg: "请输入手机号或账号" },
          { rule: /(\d{4,11})/, msg: "账号格式错误!" },
        ],
        password: [
          { rule: "required", msg: "密码不能为空" },
          {
            rule: /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,12}$/,
            msg: "密码为6-12位字母加数字",
          },
        ],
      },
      signtype: 1, //signtype 登录类型 （0:账号密码 1:手机号密码，2：邮箱密码）
    };
  },
  methods: {
    handleCloseLogin() {
      this.$emit("closeLogin");
    },
    handleShowRegister() {
      this.$emit("showRegister");
    },
    handleShowForgetPass() {
      this.$emit("showForgetPass");
    },
    doLogin(data) {
      this.$toast.loading({
        overlay: true,
        message: "登录中...",
        duration: 0,
      });
      //获取登录类型
      this.checkSignType(this.login.phone);
      let params = {
        account: this.login.phone,
        password: this.login.password,
        ticket: data.ticket,
        randstr: data.randstr,
        clientid: this.clientid,
        fromtype: 2, //注册来源（1:PC 2:wap 3:ios 4:android
        loginAccountType: 1, //账号类型（0：模拟,1：真实,2:不区分真实模拟）
        signtype: this.signtype, //signtype 登录类型 （0:账号密码 1:手机号密码，2：邮箱密码）
      };
      login.doLogin(params).then((res) => {
        this.$toast.clear();
        if (res.IsSuccess) {
          this.$toast.success("登录成功");
          //隐藏登录表单
          this.$emit("closeLogin");
          //注册成功自动登录 存储信息
          console.log(res.Data);
          localStorage.setItem("token", res.Token);
          localStorage.setItem("userType", res.Data.type); //真实or模拟用户 1真实 0模拟
          // localStorage.setItem(
          //   "userInfo",
          //   base64.encode(JSON.stringify(res.Data.jfUser))
          // );
          //同步store
          this.$store.commit("login/setToken", res.Token);
          this.$store.commit("login/setUserType", res.Data.type);
          this.$store.dispatch("login/getUserInfo", {
            password: params.password,
          });
          // 登录成功 更新socket链接
          this.$store.dispatch("socket/changeSocket", {
            account: res.Data.account,
            pwd: params.password,
          });
        } else {
          this.$toast(res.Data);
        }
      });
    },
    handleLogin() {
      var captcha = new window.TencentCaptcha(this.appId, (res) => {
        if (res.ret === 0) {
          this.doLogin(res);
        }
      });
      // 显示验证码
      captcha.show();
    },
    checkSignType(val) {
      if (!val) {
        this.$toast("请输入手机号码/交易账号");
        return false;
      }
      let regemail =
        /^([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,4}$/; //验证是否为邮箱格式；
      if (regemail.test(val)) {
        this.signtype = 2;
        return true;
      }
      let regmobile = /^((1[3-9][0-9]{1})+\d{8})$/;
      if (regmobile.test(val)) {
        this.signtype = 1;
        return true;
      }
      let regnumber = /^[0-9]*$/; //验证是否为数字
      if (regnumber.test(val)) {
        this.signtype = 0;
        //如果模拟账号登录 提示使用真实账号
        if (val.substr(0, 1) == "1") {
          this.$toast("请使用真实账号登录");
          return false;
        }
        return true;
      }
      this.$toast("手机号码/交易账号有误");
      return false;
    },
  },
  mounted() {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/assets/scss/common/login.scss";
</style>
