export const collapse = {
  data() {
    return {};
  },
  methods: {
    handleCollapse(e) {
      //当前选中的展开 关闭之前的
      let path = this.getPath(e);
      //console.log(path);
      for (let i in path) {
        let $class = path[i].getAttribute("class");
        if ($class && $class.indexOf("collapse") > -1) {
          let collapse = document.getElementsByClassName("collapse act");
          //之前是否有打开的
          if (collapse[0]) {
            if (path[i] == collapse[0]) {
              //是当前打开的  移除act 并关闭
              path[i].classList.remove("act");
            } else {
              //不是之前打开的  关闭之前打开的
              this.openOrHide(collapse[0].nextElementSibling);
              collapse[0].classList.remove("act");
              //添加act
              path[i].classList.add("act");
            }
          } else {
            //添加act
            path[i].classList.add("act");
          }
          let $node = path[i].nextElementSibling;
          this.openOrHide($node);
          return;
        }
      }
    },
    //展开或隐藏
    openOrHide($node) {
      if ($node.style.maxHeight && $node.style.maxHeight != "0px") {
        $node.style.maxHeight = "0px";
      } else {
        $node.style.maxHeight = $node.scrollHeight + "px";
      }
    },
    getPath(e) {
      let path = e.path || e.composedPath();
      if (path) {
        return path;
      }
      path = [];
      let target = e.target;
      while (target.parentNode !== null) {
        path.push(target);
        target = target.parentNode;
      }
      path.push(document, window);
      return path;
    },
  },
};
