<template>
  <transition name="slide-right" v-on:after-enter="afterEnter">
    <div class="detail-overlay" @click.self="handleMarketInfo">
      <div class="market-detail">
        <div class="market-title" @click="handleMarketInfo">
          <i :style="isWap ? 'transform: rotate(180deg);' : ''"></i>
          <span>商品详情</span>
        </div>
        <div class="market-content scrollbar">
          <div class="symbol-info">
            <p class="name-info">
              <span class="name-ch">{{ currentSymbol.cname }}</span>
              <span class="name-en">{{ currentSymbol.name }}</span>
            </p>
            <div class="price-info">
              <p
                class="price"
                :class="currentSymbol.bWave === 'up' ? 'up' : 'down'"
              >
                {{
                  NP.round(currentSymbol.b, currentSymbol.p).toFixed(
                    currentSymbol.p
                  )
                }}
                <i :class="currentSymbol.bWave === 'up' ? 'up' : 'down'"></i>
              </p>
              <p class="rate">
                <span
                  class="rise"
                  :class="currentSymbol.rise > 0 ? 'up' : 'down'"
                  >{{ currentSymbol.rise > 0 ? "+" : ""
                  }}{{ currentSymbol.rise }}</span
                >
                <span
                  class="rise"
                  :class="currentSymbol.riseRate > 0 ? 'up' : 'down'"
                  >{{ currentSymbol.riseRate > 0 ? "+" : ""
                  }}{{ currentSymbol.riseRate + "%" }}</span
                >
              </p>
            </div>
          </div>
          <!-- <div class="info-list" v-show="!isOrder || isWap">
            <div class="info-title collapse" @click="handleArrow" id="timeData">
              <span class="info-title-span">实时数据</span>
              <img
                src="@/assets/images/common/arrow.png"
                alt=""
                class="info-title-arrow"
              />
            </div>
            <TimeData ref="time" :currentSymbol="currentSymbol" />
          </div> -->
          <div class="info-list" v-show="!isOrder || isWap">
            <div class="info-title collapse" @click="handleArrow" id="ruleData">
              <span class="info-title-span">交易规则</span>
              <img
                src="@/assets/images/common/arrow.png"
                alt=""
                class="info-title-arrow"
              />
            </div>
            <Rule ref="rule" :currentSymbol="currentSymbol" />
          </div>
          <Order
            ref="order"
            v-show="isOrder"
            @closeOrderInfo="handleCloseOrderInfo"
          />
        </div>
        <div class="market-btn" v-show="!isOrder">
          <button class="button sale-button" @click="handleOrderType(true, 1)">
            <p>
              <span class="btn-text">卖出</span>
              <span class="btn-price">{{
                NP.round(currentSymbol.b, currentSymbol.p).toFixed(
                  currentSymbol.p
                )
              }}</span>
            </p>
          </button>
          <button class="button buy-button" @click="handleOrderType(true, 0)">
            <p>
              <span class="btn-text">买入</span>
              <span class="btn-price">{{
                NP.round(currentSymbol.a, currentSymbol.p).toFixed(
                  currentSymbol.p
                )
              }}</span>
            </p>
          </button>
        </div>
        <div class="order-btn" v-show="isOrder && !isWap">
          <button
            v-show="!isWap"
            class="button cancel-button"
            @click="handleCancalOrder"
          >
            取消
          </button>
          <button class="button submit-button" @click="handleSubmitOrder">
            提交订单
          </button>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { collapse } from "@/mixins/collapse.js";
//import TimeData from "@/components/market/data.vue";
import Rule from "@/components/market/rule.vue";
import Order from "@/components/market/order.vue";
export default {
  name: "MarketDetail",
  mixins: [collapse],
  components: {
    //TimeData,
    Rule,
    Order,
  },
  data() {
    return {
      orderType: 0, //下单类型 默认0买入 1卖出
      isOrder: false, //是否下单
    };
  },
  computed: {
    currentSymbol() {
      return this.$store.state.socket.currentSymbol;
    },
    isWap() {
      return this.$store.state.common.isWap;
    },
  },
  methods: {
    handleAutoClick() {
      // IE浏览器
      if (document.all) {
        document.getElementById("ruleData").click();
      }
      // 其它浏览器
      else {
        var e = document.createEvent("MouseEvents");
        e.initEvent("click", true, true);
        document.getElementById("ruleData").dispatchEvent(e);
      }
    },
    handleArrow($e) {
      this.handleCollapse($e);
    },
    //详情是否显示
    handleMarketInfo() {
      this.$store.commit("socket/setTabType", 1);
      this.$emit("closeDetail");
    },
    afterEnter() {
      if (!this.isOrder) {
        if (this.$refs["rule"] && this.$refs["rule"].$el.offsetHeight == 0) {
          this.handleAutoClick();
        }
      }
    },
    handleOrderType(isOrder, orderType) {
      this.isOrder = isOrder;
      this.orderType = orderType;
      if (isOrder) {
        this.$nextTick(() => {
          this.$refs["order"].handleOrderTypeChange(orderType);
        });
      }
    },
    handleCancalOrder() {
      this.isOrder = false;
      this.$emit("closeDetail");
    },
    //关闭下单
    handleCloseOrderInfo() {
      this.isOrder = false;
    },
    //下单
    handleSubmitOrder() {
      this.$refs["order"].handleSubmit();
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/assets/scss/market/detail.scss";
</style>
