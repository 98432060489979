import axios from "@/axios/axios.js";

const common = {
  //获取资讯
  async getCalendar(params = {}) {
    return await axios.post(
      "/cms/api/services/app/EconomicCalendar/ViewListWithHoliday",
      params,
      {
        des: "直客获取日历",
        sTime: new Date().getTime(),
      }
    );
  },
  async getActivityList(params = {}) {
    return await axios.post("/common/appPopupList", params, {
      des: "直客获取推广内容列表",
      sTime: new Date().getTime(),
    });
  },
};

export default common;
