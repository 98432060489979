import Vue from "vue";
import Vuex from "vuex";
Vue.use(Vuex);

import login from "./modules/login.js";
import common from "./modules/common.js";
import socket from "./modules/socket.js";
const store = new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    login,
    common,
    socket,
  },
});

export default store;
