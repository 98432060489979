import store from "../store/index.js";

// 获取持仓单总手续费和库存费(list:持仓列表 pricePageData:当前各个品种信息列表 )
export function getTotalFee(list, pricePageData) {
  /**
   * 持仓单字段说明（使用到的）
   * s: 品种名称
   * z:合约单位
   * v: 手数（计算时需要除以100）
   * c: 建仓类型 0、买入 1、卖出
   * q: 库存费
   * k: 手续费
   * f: 建仓价格
   * g: 止损
   * h: 止盈
   * **/
  let totalProfit = 0;
  if (list.length) {
    list.forEach((item) => {
      pricePageData.forEach((citem) => {
        if (citem.n === item.s) {
          item.d = citem.d;
          // 盈亏计算公式：买入： （实时报价Bid-建仓价格）*手数*合约单位； 卖出：（建仓价格-实时报价Ask）*手数*合约单位
          if (item.c === 1) {
            item.fee = (item.q ? item.q : 0) - (item.k ? item.k : 0); //
          } else {
            item.fee = (item.q ? item.q : 0) - (item.k ? item.k : 0); //
          }
        }
      });
      totalProfit += item.fee ? item.fee * 1 : 0;
    });
  }
  return totalProfit.toFixed(2);
}
// 以下是方法是计算保证金比例要使用到的
// 获取持仓单所有买入和卖出手数总和 list: 持仓单
export function getHoldlistAllBuyVolume(list) {
  let BuyVolume = 0;
  let SellVolume = 0;
  list.forEach((item) => {
    if (item.c == 0) {
      BuyVolume += item.v * 1;
    } else {
      SellVolume += item.v * 1;
    }
  });
  return {
    BuyVolume: BuyVolume / 100,
    SellVolume: SellVolume / 100,
  };
}
// 计算手数的一半
export function getHalfVolume(list) {
  let half = 0;
  let allVolume = getHoldlistAllBuyVolume(list);
  allVolume.BuyVolume >= allVolume.SellVolume
    ? (half = allVolume.BuyVolume - allVolume.SellVolume)
    : (half = allVolume.SellVolume - allVolume.BuyVolume);
  return half;
}
// 计算最大总手数
export function getMaxVolume(list) {
  let maxVolume = 0;
  let allVolume = getHoldlistAllBuyVolume(list);
  allVolume.BuyVolume >= allVolume.SellVolume
    ? (maxVolume = allVolume.SellVolume * 2)
    : (maxVolume = allVolume.BuyVolume * 2);
  return maxVolume;
}
//
export function getMargin(list, symbolInfo, userInfo) {
  let Margin = 0;
  list.forEach((listitem) => {
    symbolInfo.forEach((item) => {
      if (item && item.n && listitem.s === item.n) {
        Margin +=
          ((getMaxVolume(list) * item.h) / userInfo.h) * 1 +
          ((getHalfVolume(list) * item.h) / userInfo.h) * 1;
      }
    });
  });
  return Margin;
}

/*
 *其他组别 计算参考保证金
 * holdList: 持仓单
 * symbolInfo: 品种信息
 * hand: 手数
 * buyType: 0:买入 1:卖出
 * leverage: 杠杆大小
 */
export function referenceMargin(holdList, symbolInfo, hand, buyType, leverage) {
  // debugger
  let b = 0;
  let s = 0;
  holdList.length &&
    holdList.forEach((item) => {
      if (symbolInfo.name === item.s) {
        if (item.c === 0) {
          b += item.v / 100;
        } else {
          s += item.v / 100;
        }
      }
    });
  //初始保证金
  let initialMargin = symbolInfo.i;
  //锁单保证金
  let hedgedMargin = symbolInfo.h;
  //每手保证金
  let initPer = initialMargin / leverage;
  //锁单保证金
  let hedgedPer = hedgedMargin / leverage;
  let m = Math.min(b, s) * hedgedPer * 2 + Math.abs(b - s) * initPer;
  let bv = 0;
  let sv = 0;
  if (buyType === 0) {
    bv = hand * 1;
  } else {
    sv = hand * 1;
  }
  let nbv = b + bv;
  let nsv = s + sv;
  let nm = Math.min(nbv, nsv) * hedgedPer * 2 + Math.abs(nbv - nsv) * initPer;
  let result = (nm - m).toFixed(2);
  return Math.max(0, result);
}

/*
 *Proway1S_g 新组别外汇计算参考保证金 Forex
 *外汇保证金=成交额*组别默认杠杆*组别交易品种百分比 或 外汇保证金=交易额/（杠杆返回值*倍数）（目前组信息m中品种的p值为倍数）
 *即：手数*价格*合约单位/(杠杆*倍数)
 * groupInfoM: 组信息中m的值
 * symbolInfo: 品种信息
 * hand: 手数
 * buyType: 0:买入 1:卖出
 * leverage: 杠杆大小
 */
export function foreignReferenceMargin(
  groupInfoM,
  symbolInfo,
  hand,
  buyType,
  leverage
) {
  let ContractSize = symbolInfo.z; //合约单位
  //组别交易品种百分比（如果组别设置了品种的Percentage，就用组别的，如果没有，就用Symbol的）
  let percentage = symbolInfo.pt / 100; //百分比  需要/100
  if (Object.keys(groupInfoM).length > 0) {
    let mInfo = groupInfoM.find((item) => item.s == symbolInfo.name);
    percentage = mInfo ? mInfo.p : percentage; //该值实际返回为倍数
  }
  let result = 0; //本单保证金
  if (buyType === 0) {
    //买入
    result = (hand * symbolInfo.a * ContractSize) / (leverage * percentage);
  } else {
    //卖出
    result = (hand * symbolInfo.b * ContractSize) / (leverage * percentage);
  }
  //汇率转换为美元
  result = foreignCovertProfit(symbolInfo, buyType, result);
  return Math.max(0, result.toFixed(2));
}

/*
 *Proway1S_g 新组别其他计算参考保证金：CFD
 *其他保证金=成交额*组别交易品种百分比（0.5%）或 其他保证金=交易额/倍数（目前组信息m中品种的p值为倍数）
 * groupInfoM: 组信息中m的值
 * symbolInfo: 品种信息
 * hand: 手数
 * buyType: 0:买入 1:卖出
 */
export function otherReferenceMargin(groupInfoM, symbolInfo, hand, buyType) {
  let ContractSize = symbolInfo.z; //合约单位
  //组别交易品种百分比（如果组别设置了品种的Percentage，就用组别的，如果没有，就用Symbol的）
  let percentage = symbolInfo.pt / 100; //百分比  需要/100
  let p = 0; //倍数
  //查找所属组是否设置p值
  if (Object.keys(groupInfoM).length > 0) {
    let mInfo = groupInfoM.find((item) => item.s == symbolInfo.name);
    p = mInfo ? mInfo.p : 0;
  }
  console.log(percentage, p);
  let result = 0; //本单保证金
  if (buyType === 0) {
    if (p) {
      //所属组的p值存在  优先用p值  否则用品种的pt
      result = (hand * ContractSize * symbolInfo.a) / p;
    } else {
      result = hand * ContractSize * symbolInfo.a * percentage;
    }
  } else {
    if (p) {
      //所属组的p值存在  优先用p值  否则用品种的pt
      result = (hand * ContractSize * symbolInfo.b) / p;
    } else {
      result = hand * ContractSize * symbolInfo.b * percentage;
    }
  }
  //是否为指数品种  指数品种需转为美元
  if (
    Object.keys(
      store.getters["socket/defaultSymbol"].indexSymbolsList
    ).includes(symbolInfo.name)
  ) {
    result = indexCovertProfit(symbolInfo, buyType, result);
  }
  return Math.max(0, result.toFixed(2));
}

/*
 *加密货币计算保证金
 * symbolInfo: 品种信息
 * hand: 手数
 * buyType: 0:买入 1:卖出
 * holdList: 持仓单
 */
export function btcReferenceMargin(symbolInfo, hand, buyType, holdList) {
  let b = 0; //当前品种持仓单买入手数
  let s = 0; //当前品种持仓单卖出手数
  let bList = []; //当前品种持仓单买入信息列表
  let sList = []; //当前品种持仓单卖出信息列表
  holdList.length &&
    holdList.forEach((item) => {
      if (symbolInfo.name === item.s) {
        if (item.c === 0) {
          //买入
          b += item.v / 100;
          bList.push(item);
        } else {
          //卖出
          s += item.v / 100;
          sList.push(item);
        }
      }
    });
  let result = 0; //本单保证金
  if (buyType === 0) {
    //买入X手
    if (b >= s) {
      //b≥s，本单保证金=x*买入价格*保证金比例*合约单位
      result = hand * symbolInfo.a * symbolInfo.pt * 0.01 * symbolInfo.z;
    } else if (b < s && hand + b <= s) {
      //b＜s，x+b≤s 本单保证金为0美元
      result = 0;
    } else if (b < s && hand + b > s) {
      //b＜s，x+b＞s，本单保证金=当前持仓的b单保证金之和（先逐笔计算再求和）+x手保证金-s单保证金之和
      let bn = 0; //当前持仓的b单保证金之和
      let sn = 0; //当前持仓的s单保证金之和
      let p = 0; //开仓价
      let v = 0; //手数
      for (let i = 0; i < bList.length; i++) {
        p = bList[i].f; //开仓价
        v = bList[i].v / 100; //买入手数
        bn += v * p * symbolInfo.pt * 0.01 * symbolInfo.z;
      }
      for (let i = 0; i < sList.length; i++) {
        p = sList[i].f; //开仓价
        v = sList[i].v / 100; //卖出手数
        sn += v * p * symbolInfo.pt * 0.01 * symbolInfo.z;
      }
      result =
        bn + hand * symbolInfo.a * symbolInfo.pt * 0.01 * symbolInfo.z - sn;
    }
  } else {
    //卖出x手
    if (s >= b) {
      //s≥b，本单保证金=x*卖出价格*保证金比例*合约单位
      result = symbolInfo.b * hand * symbolInfo.pt * 0.01 * symbolInfo.z;
    } else if (s < b && hand + s <= b) {
      //s＜b，x+s≤b，本单保证金为0美元
      result = 0;
    } else if (s < b && hand + s > b) {
      //s＜b，x+s＞b，本单保证金=当前持仓的s单保证金之和（先逐笔计算再求和）+x手保证金-b单保证金之和
      let bn = 0; //当前持仓的b单保证金之和
      let sn = 0; //当前持仓的s单保证金之和
      let p = 0; //开仓价
      let v = 0; //手数
      for (let i = 0; i < bList.length; i++) {
        p = bList[i].f; //开仓价
        v = bList[i].v / 100; //买入手数
        bn += v * p * symbolInfo.pt * 0.01 * symbolInfo.z;
      }
      for (let i = 0; i < sList.length; i++) {
        p = sList[i].f; //开仓价
        v = sList[i].v / 100; //卖出手数
        sn += v * p * symbolInfo.pt * 0.01 * symbolInfo.z;
      }
      result =
        sn + hand * symbolInfo.b * symbolInfo.pt * 0.01 * symbolInfo.z - bn;
    }
  }
  return Math.max(0, result.toFixed(2));
}

// export function unique(arr) {
//     let obj = {}
//     return arr.filter((item, index) => {
//         // 防止key重复
//         let newItem = item + JSON.stringify(item)
//         return obj.hasOwnProperty(newItem) ? false : obj[newItem] = true
//     })
// }
export function unique(arr) {
  for (var i = 0; i < arr.length; i++) {
    for (var j = i + 1; j < arr.length; j++) {
      if (arr[i].name == arr[j].name) {
        //第一个等同于第二个，splice方法删除第二个
        arr.splice(j, 1);
        j--;
      }
    }
  }
  return arr;
}

/*计算时间
 * timeStamp 返回的时间戳
 * timeZone  返回的时区（z）, timeZone
 *返回z=2 为东2区时区  显示东2区时间
 *返回z=0 为0时区  显示0时区时间
 */
export function formatTime(timeStamp) {
  //判断是否为时间戳
  if (typeof timeStamp == "string" && timeStamp.indexOf("-") > -1) {
    //转为时间戳
    timeStamp = Date.parse(timeStamp);
  }
  let date = new Date((timeStamp + 0 * 60 * 60) * 1000);
  return (
    formatNum(date.getUTCHours()) +
    ":" +
    formatNum(date.getUTCMinutes()) +
    ":" +
    formatNum(date.getUTCSeconds())
  );
}

function formatNum(num) {
  return num < 10 ? "0" + num : num;
}

//外汇品种 汇率转为美元
export function foreignCovertProfit(symbolInfo, buyType, profit) {
  if (symbolInfo.name.substring(3, 6) !== "USD") {
    let divName = "USD" + symbolInfo.name.substring(3, 6);
    let mulName = symbolInfo.name.substring(3, 6) + "USD";
    let sInfo = store.state.socket.symbolList.find(
      (item) => item.name === divName
    );
    if (sInfo && sInfo.name) {
      if (buyType === 0) {
        profit = profit / sInfo.b;
      } else {
        profit = profit / sInfo.a;
      }
    } else {
      let sInfo1 = store.state.socket.symbolList.find(
        (item) => item.name === mulName
      );
      if (sInfo1 && sInfo1.name) {
        if (buyType === 0) {
          profit = profit * sInfo1.b;
        } else {
          profit = profit * sInfo1.a;
        }
      }
    }
  }
  return profit;
}

//指数品种 汇率转为美元
export function indexCovertProfit(symbolInfo, buyType, profit) {
  let divName = "USD" + symbolInfo.c;
  let mulName = symbolInfo.c + "USD";

  let sInfo = store.state.socket.symbolList.find(
    (item) => item.name === divName
  );
  if (sInfo && sInfo.name) {
    if (buyType === 0) {
      profit = profit / sInfo.b;
    } else {
      profit = profit / sInfo.a;
    }
  } else {
    let sInfo1 = store.state.socket.symbolList.find(
      (item) => item.name === mulName
    );
    if (sInfo1 && sInfo1.name) {
      if (buyType === 0) {
        profit = profit * sInfo1.b;
      } else {
        profit = profit * sInfo1.a;
      }
    }
  }
  return profit;
}

//计算占用保证金
export function calculationMargin(symbolList) {
  let margin = 0; //总保证金
  //console.log(symbolList, 417)
  for (let key in symbolList) {
    //console.log(key)
    //同一品种的买单的总保证和卖单的总保证金相比，哪个大用哪个（即买单和卖单哪个价高）
    //买多算买的（买的多自然买价高），卖多算卖的，一样算总价高的(暂不用这个算  感觉比较麻烦)
    //计算品种买卖订单手数
    let buyHand = 0; //当前品种买入手数和
    let sellHand = 0; //当前品种卖出手数和
    let buyPrice = 0; //当前品种买入手数价格和
    let sellPrice = 0; //当前品种卖出手数价格和
    let buyList = []; //当前品种买入列表
    let sellList = []; //当前品种买入列表
    let buyType = 0; //买入卖出类型 0买入 1卖出
    symbolList[key].forEach((items) => {
      if (items.c === 1) {
        sellHand += items.v / 100;
        sellPrice += (items.v / 100) * items.f;
        sellList.push(items);
      } else {
        buyHand += items.v / 100;
        buyPrice += (items.v / 100) * items.f;
        buyList.push(items);
      }
    });
    if (buyPrice >= sellPrice) {
      //console.log(11)
      buyType = 0;
      margin += calculationSymbolMargin(
        buyList,
        key,
        buyType,
        buyHand,
        sellHand
      );
    } else {
      //console.log(22)
      buyType = 1;
      margin += calculationSymbolMargin(
        sellList,
        key,
        buyType,
        buyHand,
        sellHand
      );
    }
    //console.log(margin, 451)
  }
  return margin;
}

//计算单个品种保证金
export function calculationSymbolMargin(
  list,
  name,
  buyType,
  buyHand,
  sellHand
) {
  //加密货币
  let margin = 0;
  let symbolInfo = store.state.socket.symbolList.find(
    (item) => item.name === name
  );
  if (
    Object.keys(store.getters["socket/defaultSymbol"].btcSymbolsList).includes(
      name
    )
  ) {
    list.forEach((item) => {
      //本单保证金=x*价格*保证金比例*合约单位
      margin += (item.v / 100) * item.f * symbolInfo.pt * 0.01 * symbolInfo.z;
    });
    //console.log(margin, 502)
    return margin;
  }
  //新组别 非 加密货币
  if (
    store.state.socket.wsLoginInfo.g == "Proway1S_g" ||
    store.state.socket.wsLoginInfo.g == "Proway1S_demo_g"
  ) {
    //组别交易品种百分比（如果组别设置了品种的Percentage，就用组别的，如果没有，就用Symbol的）
    let percentage = symbolInfo.pt / 100; //百分比 需要/100
    let p = 0;
    if (Object.keys(store.state.socket.symbolGroup.m).length > 0) {
      let mInfo = store.state.socket.symbolGroup.m.find(
        (item) => item.s == symbolInfo.name
      );
      p = mInfo ? mInfo.p : 0; //倍数
    }
    //外汇类
    if (
      Object.keys(
        store.getters["socket/defaultSymbol"].foreignSymbolsList
      ).includes(name)
    ) {
      let leverage = store.state.socket.wsLoginInfo.h; //杠杆
      list.forEach((item) => {
        //本单保证金=交易额/（杠杆返回值*倍数）（即：手数*价格*合约单位/(杠杆*倍数)）
        margin +=
          ((item.v / 100) * item.f * symbolInfo.z) / (leverage * percentage);
      });
      //console.log(margin, 522)
      //汇率转换为美元
      margin = foreignCovertProfit(symbolInfo, buyType, margin);
      //console.log(margin, 522)
      return margin;
    } else {
      //本单保证金=交易额/倍数（即：手数*价格*合约单位/倍数）或者 交易额*百分比（即：手数*价格*合约单位*百分比）
      if (p) {
        //所属组的p值存在  优先用p值  否则用品种的pt
        list.forEach((item) => {
          margin += ((item.v / 100) * item.f * symbolInfo.z) / p;
        });
      } else {
        list.forEach((item) => {
          margin += (item.v / 100) * item.f * symbolInfo.z * percentage;
        });
      }
      //如果是指数品种  需转为美元
      if (
        Object.keys(
          store.getters["socket/defaultSymbol"].indexSymbolsList
        ).includes(name)
      ) {
        margin = indexCovertProfit(symbolInfo, buyType, margin);
      }
      //console.log(margin, 533)
      return margin;
    }
  } else {
    //初始保证金
    let initialMargin = symbolInfo.i;
    //锁单保证金
    let hedgedMargin = symbolInfo.h;
    //每手保证金 = 初始保证金/杠杆
    let leverage = store.state.wsLoginInfo.h;
    let initPer = initialMargin / leverage;
    //锁单保证金 = 锁单保证金/杠杆
    let hedgedPer = hedgedMargin / leverage;
    margin =
      Math.min(buyHand, sellHand) * hedgedPer * 2 +
      Math.abs(buyHand - sellHand) * initPer;
    return margin;
  }
  //return margin
}
