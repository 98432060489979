import base64 from "@/utils/base64.js";
import login from "@/apis/login.js";
const state = {
  token: localStorage.getItem("token") ? localStorage.getItem("token") : "",
  userInfo:
    localStorage.getItem("token") && localStorage.getItem("userInfo")
      ? JSON.parse(base64.decode(localStorage.getItem("userInfo")))
      : {}, //使用原生的base64解码window.atob  从localStorage取为了下次自动登录
  userType: localStorage.getItem("userType")
    ? localStorage.getItem("userType")
    : "", // 1真实 0模拟
};

const mutations = {
  //设置登录的帐号类型
  setUserType(state, type) {
    state.userType = type;
  },
  // 设置token
  setToken(state, token) {
    state.token = token;
  },
  // 设置登录用户信息
  setUserInfo(state, info) {
    state.userInfo = info;
  },
};

const actions = {
  async getUserInfo({ commit }, data) {
    // login.getUserInfo().then((res) => {
    //   if (res.IsSuccess) {
    //     res.Data.jfUser.jfUserBindcard = "";
    //     commit("setUserInfo", Object.assign(res.Data.jfUser, data));
    //     localStorage.setItem(
    //       "userInfo",
    //       base64.encode(JSON.stringify(Object.assign(res.Data.jfUser, data)))
    //     );
    //     return res.Data.jfUser;
    //   }
    // });
    let res = await login.getUserInfo();
    if (res.IsSuccess) {
      res.Data.jfUser.jfUserBindcard = "";
      commit("setUserInfo", Object.assign(res.Data.jfUser, data));
      localStorage.setItem(
        "userInfo",
        base64.encode(JSON.stringify(Object.assign(res.Data.jfUser, data)))
      );
      return res.Data.jfUser;
    } else {
      return {};
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
