<template>
  <div
    class="left is-show-large is-show-medium"
    :class="isShowMenu ? 'open-menu' : ''"
  >
    <div class="left-top">
      <router-link to="/" @click.native="handleClickLeftMenu">
        <div class="left-btn left-logo">
          <img src="@/assets/images/common/pc/logo.png" alt="JF" class="logo" />
        </div>
      </router-link>
      <div class="left-btn" @click="handleMenu">
        <img
          v-if="isShowMenu"
          src="@/assets/images/common/pc/close-menu.png"
          alt="菜单"
          class="menu"
        />
        <img
          v-else
          src="@/assets/images/common/pc/menu.png"
          alt="菜单"
          class="menu"
        />
        <span>菜单</span>
      </div>
      <router-link to="/" @click.native="handleClickLeftMenu">
        <div
          class="left-btn"
          :class="{ active: currentMenu.pathWay == 0 || !currentMenu.pathWay }"
        >
          <img
            src="@/assets/images/common/pc/market.png"
            alt="行情"
            class="market"
          />
          <span>行情</span>
        </div>
      </router-link>
      <router-link to="/trade" @click.native="handleClickLeftMenu">
        <div class="left-btn" :class="{ active: currentMenu.pathWay == 1 }">
          <img
            src="@/assets/images/common/pc/trade.png"
            alt="交易"
            class="trade"
          />
          <span>交易</span>
        </div>
      </router-link>
      <router-link to="/activity" @click.native="handleClickLeftMenu">
        <div class="left-btn" :class="{ active: currentMenu.pathWay == 4 }">
          <img
            src="@/assets/images/common/pc/activity.png"
            alt="领红包"
            class="activity"
          />
          <span>领红包</span>
        </div>
      </router-link>
      <router-link to="/calendar" @click.native="handleClickLeftMenu">
        <div class="left-btn" :class="{ active: currentMenu.pathWay == 6 }">
          <img
            src="@/assets/images/common/pc/calendar.png"
            alt="财经日历"
            class="calendar"
          />
          <span>财经日历</span>
        </div>
      </router-link>
    </div>

    <!-- <Activity v-show="isShowActivity" ref="activity" /> -->
    <div class="left-bottom" v-if="userInfo && userInfo.account">
      <div class="left-btn" @click="handleSwitch">
        <img
          src="@/assets/images/common/pc/change.png"
          alt="切换角色"
          class="change-role"
        />
        <span>切换角色</span>
      </div>
    </div>
  </div>
</template>

<script>
import { switchRole } from "@/mixins/switchRole.js";
import { mapState } from "vuex";
//import Activity from "@/components/activity";
export default {
  name: "Left",
  mixins: [switchRole],
  // components: {
  //   Activity,
  // },
  data() {
    return {};
  },
  computed: {
    userInfo() {
      return this.$store.state.login.userInfo;
    },
    ...mapState("common", ["isShowMenu", "isShowActivity", "currentMenu"]),
  },
  methods: {
    handleMenu() {
      //如果此时打开活动 关闭活动
      if (this.isShowActivity) {
        this.$store.commit("common/setIsShowActivity", false);
      }
      if (this.isShowMenu) {
        this.$store.commit("common/setIsShowMenu", false);
      } else {
        this.$store.commit("common/setIsShowMenu", true);
      }
    },
    handleClickLeftMenu() {
      //如果此时打开活动 关闭活动
      if (this.isShowActivity) {
        this.$store.commit("common/setIsShowActivity", false);
      }
      if (this.isShowMenu) {
        this.$store.commit("common/setIsShowMenu", false);
      }
    },
    handleActivity() {
      //如果此时打开菜单 关闭菜单
      if (this.isShowMenu) {
        this.$store.commit("common/setIsShowMenu", false);
      }
      //设置活动显示状态
      if (this.isShowActivity) {
        this.$store.commit("common/setIsShowActivity", false);
      } else {
        //判断是否登录
        if (!this.userInfo || !this.userInfo.account) {
          this.$store.commit("common/setIsShowLogin", true);
          return false;
        }
        this.$store.commit("common/setIsShowActivity", true);
        //设置当前选中菜单  由于菜单不在router中配置 所以默认pathWay=999
        this.$store.commit("common/setCurrentMenu", {
          name: "活动",
          pathWay: 999,
        });
        //获取活动列表
        if (this.isShowActivity) {
          this.$refs["activity"].getList();
        }
      }
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/common/left.scss";
</style>
