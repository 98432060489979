<template>
  <transition name="slide-up">
    <div class="detail-order-overlay" @click.self="handleOrderInfo">
      <div class="market-order">
        <div class="order-tab">
          <Tab
            ref="orderTab"
            :tabList="tabList"
            @changeTab="handleChangeTab"
            :tabIndex="tabType"
          />
        </div>
        <p class="symbol-name" v-show="isWap">{{ currentSymbol.nCns }}</p>
        <div class="buy-sale">
          <p
            class="sale"
            :class="this.orderType == 1 ? 'act' : ''"
            @click="handleOrderChange(1)"
          >
            <span class="buy-sale-text">卖出</span>
            <span class="buy-sale-price">{{ Bid }}</span>
          </p>
          <p
            class="buy"
            :class="this.orderType == 0 ? 'act' : ''"
            @click="handleOrderChange(0)"
          >
            <span class="buy-sale-text">买入</span>
            <span class="buy-sale-price">{{ Ask }}</span>
          </p>
        </div>
        <div class="order-info">
          <div class="hung-type" v-show="tabType == 2">
            <span class="hung-name">挂单类型</span>
            <p class="hung-item">
              <span
                @click="handleHungOrderTypeChange(0)"
                :class="hungOrderType === 0 ? 'act' : ''"
                >限价</span
              >
              <span
                @click="handleHungOrderTypeChange(1)"
                :class="hungOrderType === 1 ? 'act' : ''"
                >止损</span
              >
            </p>
          </div>
          <div class="order-price order-input" v-show="tabType == 2">
            <p class="order-input-title">
              <span class="span-title" :class="gdClass ? '' : 'error'"
                >成交价格</span
              >
              <span
                class="span-price"
                :class="gdClass ? '' : 'error'"
                v-show="hungLimitPrice"
                >[{{ gdfuhao === 1 ? "≥" : "≤" }}{{ hungLimitPrice }}]</span
              >
            </p>
            <div class="order-input-div">
              <img
                src="~@/assets/images/market/decrease.png"
                alt=""
                class="order-input-img decrease"
                @click="handleHangPriceChange(false)"
              />
              <input
                type="text"
                class="order-input-number"
                v-model="hungPrice"
                @input="handleHungPriceInput"
              />
              <img
                src="~@/assets/images/market/add.png"
                alt=""
                class="order-input-img add"
                @click="handleHangPriceChange(true)"
              />
            </div>
          </div>
          <div class="order-lots order-input">
            <p class="order-input-title">
              <span class="span-title" :class="ssClass ? '' : 'error'"
                >交易手数</span
              >
              <span class="span-price" :class="ssClass ? '' : 'error'"
                >[{{ minLot }}-{{ maxLot }}]</span
              >
            </p>
            <div class="order-input-div">
              <img
                src="~@/assets/images/market/decrease.png"
                alt=""
                class="order-input-img decrease"
                @click="handleNumChange(false)"
              />
              <input
                type="text"
                class="order-input-number"
                v-model="handLot"
                @input="handleNumberInput"
                @blur="handleNumberBlur"
              />
              <img
                src="~@/assets/images/market/add.png"
                alt=""
                class="order-input-img add"
                @click="handleNumChange(true)"
              />
            </div>
          </div>
          <div class="hand-list">
            <p
              class="hand-item"
              v-for="(item, index) in handList"
              :class="item * 1 === handLot * 1 ? 'hand-select' : ''"
              :key="index"
              @click="handleSelectHand(item)"
            >
              {{ item }}手
            </p>
          </div>
          <div class="reference-margin">
            <span>参考保证金：{{ refMoney }}</span>
            <span
              >可用保证金：{{
                isNaN(wsLoginInfo.kybzj) ? "0.00" : wsLoginInfo.kybzj
              }}</span
            >
          </div>
          <div class="hung-type deadline" v-show="tabType == 2">
            <span class="hung-name">有效期</span>
            <p class="hung-item">
              <span
                @click="handleValidityValueChange(2)"
                :class="validityValue === 2 ? 'act' : ''"
                >本周</span
              >
              <span
                @click="handleValidityValueChange(1)"
                :class="validityValue === 1 ? 'act' : ''"
                >当天</span
              >
            </p>
          </div>
          <div class="profit-loss">
            <p class="profit-loss-title collapse" @click="handleShowProfitLoss">
              <span>止盈止损</span>
              <img
                src="~@/assets/images/common/arrow.png"
                alt=""
                class="arrow"
              />
            </p>
            <div class="profit-loss-div">
              <p class="profit-loss-type" v-show="isShowProfitLossType">
                <span
                  @click="handleLossProfitTypeChange(0)"
                  :class="lossProfitType === 0 ? 'act' : ''"
                  >价格</span
                >
                <span
                  @click="handleLossProfitTypeChange(1)"
                  :class="lossProfitType === 1 ? 'act' : ''"
                  >点数</span
                >
              </p>
              <div class="order-input">
                <p class="order-input-title">
                  <span class="span-title" :class="zyClass ? '' : 'error'"
                    >止盈</span
                  >
                  <span
                    class="span-price"
                    :class="zyClass ? '' : 'error'"
                    v-show="limitProfitPrice"
                    >[{{ zyfuhao === 1 ? "≥" : "≤"
                    }}{{ limitProfitPrice }}]</span
                  >
                </p>
                <div class="order-input-div">
                  <img
                    src="~@/assets/images/market/decrease.png"
                    alt=""
                    class="order-input-img decrease"
                    @click="handleStopSurplusChange(false)"
                  />
                  <input
                    type="text"
                    class="order-input-number"
                    placeholder="设置止盈"
                    v-model="profitPrice"
                    @focus="handleSurPlusFocus"
                    @input="handleSurPlusInput"
                  />
                  <img
                    src="~@/assets/images/market/add.png"
                    alt=""
                    class="order-input-img add"
                    @click="handleStopSurplusChange(true)"
                  />
                  <p class="estimated-profit-loss">
                    [ 预计盈亏：{{ zyProfit }} ]
                  </p>
                </div>
              </div>
              <div class="order-input">
                <p class="order-input-title">
                  <span class="span-title" :class="zsClass ? '' : 'error'"
                    >止损</span
                  >
                  <span
                    class="span-price"
                    :class="zsClass ? '' : 'error'"
                    v-show="limitLossPrice"
                    >[{{ zsfuhao === 1 ? "≥" : "≤"
                    }}{{ Math.abs(limitLossPrice) }}]</span
                  >
                </p>
                <div class="order-input-div">
                  <img
                    src="~@/assets/images/market/decrease.png"
                    alt=""
                    class="order-input-img decrease"
                    @click="handleStopLossChange(false)"
                  />
                  <input
                    type="text"
                    class="order-input-number"
                    placeholder="设置止损"
                    v-model="lossPrice"
                    @focus="handleLossFocus"
                    @input="handleStopLossInput"
                  />
                  <img
                    src="~@/assets/images/market/add.png"
                    alt=""
                    class="order-input-img add"
                    @click="handleStopLossChange(true)"
                  />
                  <p class="estimated-profit-loss">
                    [ 预计盈亏：{{ zsProfit }} ]
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="order-btn" v-show="isWap">
          <button
            class="button submit-button"
            @click="handleSubmit"
            :disabled="payLoading"
          >
            提交订单
          </button>
        </div>

        <!-- 下单成功提示 -->
        <Dialog
          ref="orderSucc"
          :dialogObj="dialogObj2"
          @confirm="handleOrderSuccConfirm"
          @cancel="handleOrderSuccCancel"
        >
          <div class="dialog-detail">
            <p class="dialog-p">
              <span class="p-title">订单编号</span>
              <span class="p-val">{{ succInfo.i }}</span>
            </p>
            <p class="dialog-p">
              <span class="p-title">交易品种</span>
              <span class="p-val">{{
                succInfo.cname ? succInfo.cname : succInfo.s
              }}</span>
            </p>
            <p class="dialog-p">
              <span class="p-title">交易类型</span>
              <span class="p-val">{{
                succInfo.c === 0 ? "买入" : "卖出"
              }}</span>
            </p>
            <p class="dialog-p">
              <span class="p-title">手数</span>
              <span class="p-val">{{ succInfo.v / 100 }}</span>
            </p>
            <p class="dialog-p">
              <span class="p-title">开仓价格</span>
              <span class="p-val">{{ succInfo.f }}</span>
            </p>
            <p class="dialog-p">
              <span class="p-title">开仓时间</span>
              <span class="p-val">{{
                succInfo.b ? succInfo.b.replace("T", " ") : ""
              }}</span>
            </p>
          </div>
        </Dialog>
        <!-- 下单失败提示 -->
        <Dialog
          ref="orderError"
          :dialogObj="dialogObj1"
          @confirm="handleOrderErrorConfirm"
          @cancel="handleOrderErrorCancel"
        />
      </div>
    </div>
  </transition>
</template>

<script>
import Tab from "@/components/tab.vue";
import { collapse } from "@/mixins/collapse.js";
import { mapState, mapMutations } from "vuex";
import { referenceMargin } from "@/utils/socketCommon.js";
import Dialog from "@/components/dialog.vue";
export default {
  name: "MarketOrder",
  components: {
    Tab,
    Dialog,
  },
  mixins: [collapse],
  data() {
    return {
      tabList: [
        {
          label: "市价",
          value: "1",
        },
        {
          label: "挂单",
          value: "2",
        },
      ],
      handList: [0.01, 0.05, 0.1, 0.5, 1.0],
      hungOrderType: 0, // 0、限价  1、止损
      orderType: 0, // 0、买入  1、卖出
      lossProfitType: 0, // 止损止盈类型 0、价格  1、点数
      validityValue: 2, // 有效期 1、当日 2、当周
      hungPrice: "", // 挂单价格
      handLot: 0.01, // 订单手数
      lossPrice: "", // 止损价格
      profitPrice: "", // 止盈价格
      ssClass: true, // 判断手数输入是否正确
      refMoney: 0, //参考保证金
      //minLot: 0.01, //最小手数限制
      //maxLot: 20, //最大手数限制
      dialogObj1: {},
      dialogObj2: {},
      succInfo: {}, //下单成功信息
      isShowProfitLossType: false, //是否显示止盈止损
    };
  },
  computed: {
    ...mapState("socket", [
      "currentSymbol",
      "symbolGroup",
      "payLoading",
      "tabType",
      "trader",
    ]),
    isWap() {
      return this.$store.state.common.isWap;
    },
    groupInfo() {
      //let symbolGroup = this.$store.state.socket.symbolGroup;
      return this.symbolGroup && this.symbolGroup.s && this.currentSymbol.g
        ? this.symbolGroup.s[this.currentSymbol.g]
        : {}; // 当前选中品种的分组信息
    },
    minLot() {
      let minLot = this.groupInfo.i ? this.groupInfo.i / 100 : 0.01; //需除100
      return minLot;
    },
    maxLot() {
      return this.groupInfo.a ? this.groupInfo.a / 100 : 20; //需除100
    },
    // 当前品种卖出实时价格
    Bid() {
      return (parseFloat(this.currentSymbol.b) || 0).toFixed(
        this.currentSymbol.p
      );
    },
    // 当前品种买入实时价格
    Ask() {
      return (parseFloat(this.currentSymbol.a) || 0).toFixed(
        this.currentSymbol.p
      );
    },
    wsLoginInfo() {
      return this.$store.state.socket.wsLoginInfo;
    },
    // 挂单符号
    gdfuhao() {
      let gdfuhao = 1;
      // 1 大于等于 2 小于等于 orderType: 0 买入 1卖出   hungOrderType 0 现价 1止损
      if (this.orderType === 1 && this.hungOrderType === 0) {
        // 卖出限价
        gdfuhao = 1;
      } else if (this.orderType === 1 && this.hungOrderType === 1) {
        // 卖出止损
        gdfuhao = 2;
      } else if (this.orderType === 0 && this.hungOrderType === 1) {
        // 买入止损
        gdfuhao = 1;
      } else if (this.orderType === 0 && this.hungOrderType === 0) {
        // 买入限价
        gdfuhao = 2;
      }
      return gdfuhao;
    },
    // 挂单限制价格（最大最小）
    hungLimitPrice() {
      let price = 0;
      if (this.orderType === 1 && this.hungOrderType === 0) {
        // 卖出限价
        price = (
          (this.Bid || 0) * 1 +
          (this.currentSymbol["j"] * this.currentSymbol["p1"] || 0) * 1
        ).toFixed(this.currentSymbol.p);
      } else if (this.orderType === 0 && this.hungOrderType === 0) {
        // 买入限价
        price = (
          (this.Ask || 0) * 1 -
          (this.currentSymbol["j"] * this.currentSymbol["p1"] || 0) * 1
        ).toFixed(this.currentSymbol.p);
      } else if (this.orderType === 1 && this.hungOrderType === 1) {
        // 卖出止损
        price = (
          (this.Bid || 0) * 1 -
          (this.currentSymbol["j"] * this.currentSymbol["p1"] || 0) * 1
        ).toFixed(this.currentSymbol.p);
      } else if (this.orderType === 0 && this.hungOrderType === 1) {
        // 买入止损
        price = (
          (this.Ask || 0) * 1 +
          (this.currentSymbol["j"] * this.currentSymbol["p1"] || 0) * 1
        ).toFixed(this.currentSymbol.p);
      }
      return price;
    },
    //挂单类
    gdClass() {
      if (this.gdfuhao === 1) {
        // 大于等于
        return this.hungPrice - this.hungLimitPrice >= 0 ? true : false;
      } else {
        // 小于等于
        return this.hungPrice - this.hungLimitPrice <= 0 ? true : false;
      }
    },
    //  止损符号(挂单和市价相同)
    zsfuhao() {
      // 1 大于等于 2 小于等于
      if (this.lossProfitType === 1) {
        // 点数
        return 1;
      } else {
        // 价格
        if (this.orderType === 0) {
          // 买入
          return 2;
        } else {
          // 卖出
          return 1;
        }
      }
    },
    // 止损价格限制（最大最小）
    limitLossPrice() {
      let price = 0;
      if (this.tabType === 1) {
        // 市价
        if (this.lossProfitType === 1) {
          // 点数
          price = this.currentSymbol["j"];
        } else {
          // 价格
          if (this.orderType === 0) {
            // 市价买入
            price = (
              (this.Bid || 0) * 1 -
              (this.currentSymbol["j"] * this.currentSymbol["p1"] || 0) * 1
            ).toFixed(this.currentSymbol.p);
          } else if (this.orderType === 1) {
            // 市价卖出
            price = (
              (this.Ask || 0) * 1 +
              (this.currentSymbol["j"] * this.currentSymbol["p1"] || 0) * 1
            ).toFixed(this.currentSymbol.p);
          }
        }
      } else {
        // 挂单
        if (this.lossProfitType === 1) {
          // 点数
          price = this.currentSymbol["j"];
        } else {
          // 价格
          if (this.orderType === 0) {
            // 挂单买入
            price = (
              (this.hungPrice || 0) * 1 -
              (this.currentSymbol["j"] * this.currentSymbol["p1"] || 0) * 1
            ).toFixed(this.currentSymbol.p);
          } else if (this.orderType === 1) {
            // 挂单卖出
            price = (
              (this.hungPrice || 0) * 1 -
              (this.currentSymbol["j"] * this.currentSymbol["p1"] || 0) * 1
            ).toFixed(this.currentSymbol.p);
          }
        }
      }
      return price;
    },
    zsClass() {
      if (this.lossPrice !== 0 && this.lossPrice !== "") {
        if (this.zsfuhao === 1) {
          // 大于等于
          return this.lossPrice - this.limitLossPrice >= 0 ? true : false;
        } else {
          // 小于等于
          return this.lossPrice - this.limitLossPrice <= 0 ? true : false;
        }
      } else {
        return true;
      }
    },
    // 止盈符号(挂单和市价相同)
    zyfuhao() {
      // 1 大于等于 2 小于等于
      if (this.lossProfitType === 1) {
        // 点数
        return 1;
      } else {
        // 价格
        if (this.orderType === 0) {
          // 买入
          return 1;
        } else {
          // 卖出
          return 2;
        }
      }
    },
    // 止盈价格限制（最大最小）
    limitProfitPrice() {
      let price = 0;
      if (this.tabType === 1) {
        // 市价
        if (this.lossProfitType === 1) {
          // 点数
          price = this.currentSymbol["j"];
        } else {
          // 价格
          if (this.orderType === 1) {
            // 市价卖出
            price = (
              (this.Ask || 0) * 1 -
              (this.currentSymbol["j"] * this.currentSymbol["p1"] || 0) * 1
            ).toFixed(this.currentSymbol.p);
          } else if (this.orderType === 0) {
            // 市价买入
            price = (
              (this.Bid || 0) * 1 +
              (this.currentSymbol["j"] * this.currentSymbol["p1"] || 0) * 1
            ).toFixed(this.currentSymbol.p);
          }
        }
      } else {
        // 挂单
        if (this.lossProfitType === 1) {
          // 点数
          price = this.currentSymbol["j"];
        } else {
          // 价格
          if (this.orderType === 1) {
            // 挂单卖出
            price = (
              (this.hungPrice || 0) * 1 -
              (this.currentSymbol["j"] * this.currentSymbol["p1"] || 0) * 1
            ).toFixed(this.currentSymbol.p);
          } else if (this.orderType === 0) {
            // 挂单买入
            price = (
              (this.hungPrice || 0) * 1 +
              (this.currentSymbol["j"] * this.currentSymbol["p1"] || 0) * 1
            ).toFixed(this.currentSymbol.p);
          }
        }
      }
      return price;
    },
    zyClass() {
      if (this.profitPrice !== 0 && this.profitPrice !== "") {
        if (this.zyfuhao === 1) {
          // 大于等于
          return this.profitPrice - this.limitProfitPrice >= 0 ? true : false;
        } else {
          // 小于等于
          return this.profitPrice - this.limitProfitPrice <= 0 ? true : false;
        }
      } else {
        return true;
      }
    },
    //止盈  预计盈亏
    zyProfit() {
      if (!this.profitPrice) {
        return "--";
      }
      let openPrice = 0;
      let profitPrice = 0;
      let zyProfit = 0;
      if (this.orderType == 1) {
        //卖出   profit = (开仓价-当前买价)*手数*合约单位
        if (this.tabType == 2) {
          openPrice = this.hungPrice;
        } else {
          openPrice = this.Bid;
        }
        if (this.lossProfitType == 1) {
          //点数
          profitPrice = (
            this.Ask * 1 -
            this.profitPrice * this.currentSymbol["p1"] * 1
          ).toFixed(this.currentSymbol.p);
        } else {
          //价格
          profitPrice = this.profitPrice;
        }
        zyProfit =
          (openPrice * 1 - profitPrice * 1) *
          this.handLot *
          this.currentSymbol.z;
      } else {
        //买入   profit = (当前卖价-开仓价)*手数*合约单位
        if (this.tabType == 2) {
          openPrice = this.hungPrice;
        } else {
          openPrice = this.Ask;
        }
        if (this.lossProfitType == 1) {
          //点数
          profitPrice = (
            this.Bid * 1 +
            this.profitPrice * this.currentSymbol["p1"] * 1
          ).toFixed(this.currentSymbol.p);
        } else {
          //价格
          profitPrice = this.profitPrice;
        }
        //console.log(profitPrice, openPrice);
        zyProfit =
          (profitPrice * 1 - openPrice * 1) *
          this.handLot *
          this.currentSymbol.z;
      }
      return zyProfit.toFixed(this.currentSymbol.p);
    },
    //止损  预计盈亏
    zsProfit() {
      if (!this.lossPrice) {
        return "--";
      }
      let openPrice = 0;
      let lossPrice = 0;
      let zsProfit = 0;
      if (this.orderType == 1) {
        //卖出   profit = (开仓价-当前买价)*手数*合约单位
        if (this.tabType == 2) {
          openPrice = this.hungPrice;
        } else {
          openPrice = this.Bid;
        }
        if (this.lossProfitType == 1) {
          //点数
          lossPrice = (
            this.Ask * 1 +
            this.lossPrice * this.currentSymbol["p1"] * 1
          ).toFixed(this.currentSymbol.p);
        } else {
          //价格
          lossPrice = this.lossPrice;
        }
        zsProfit =
          (openPrice * 1 - lossPrice * 1) * this.handLot * this.currentSymbol.z;
      } else {
        //买入   profit = (当前卖价-开仓价)*手数*合约单位
        if (this.tabType == 2) {
          openPrice = this.hungPrice;
        } else {
          openPrice = this.Ask;
        }
        if (this.lossProfitType == 1) {
          //点数
          lossPrice = (
            this.Bid * 1 -
            this.lossPrice * this.currentSymbol["p1"] * 1
          ).toFixed(this.currentSymbol.p);
        } else {
          //价格
          lossPrice = this.lossPrice;
        }
        //console.log(lossPrice, openPrice);
        zsProfit =
          (lossPrice * 1 - openPrice * 1) * this.handLot * this.currentSymbol.z;
      }
      return zsProfit.toFixed(this.currentSymbol.p);
    },
  },
  methods: {
    //是否正在下单
    ...mapMutations("socket", ["setPayLoading"]),
    // 切换顶部下单类型 市价/挂单
    handleChangeTab(index) {
      this.$store.commit("socket/setTabType", index);
      if (index === 2) {
        this.handleHangPrice();
      }
      this.lossPrice = "";
      this.profitPrice = "";
    },
    // 是否显示止盈止损
    handleShowProfitLoss($e) {
      this.isShowProfitLossType = !this.isShowProfitLossType;
      this.handleCollapse($e);
    },
    // 买入卖出切换
    handleOrderTypeChange(orderType) {
      setTimeout(() => {
        if (this.$parent.$refs["detail"]) {
          this.$refs["orderTab"].setTabline(2);
        } else {
          this.$refs["orderTab"].setTabline(1);
        }
      }, 300);
      this.orderType = orderType;
      // 计算挂单时价格的默认值
      if (this.tabType == 2) {
        this.handleHangPrice();
      }
      //止损、止盈默认价格 默认为空
      this.lossPrice = "";
      this.profitPrice = "";
      //计算参考保证金
      this.handleRefMoney();
    },
    //是否显示下单
    handleOrderInfo() {
      this.$store.commit("socket/setTabType", 1);
      this.$emit("closeOrderInfo");
    },
    // 计算参考保证金
    handleRefMoney() {
      let holdList = this.$store.state.socket.tradeList.filter(
        (item) => item.c === 1 || item.c === 0
      ); //市价买入卖出的持仓
      let leverage = this.$store.state.socket.wsLoginInfo.h; //杠杆
      let result = "";
      //原组别 外汇和其他品种保证金计算方式相同
      result = referenceMargin(
        holdList,
        this.currentSymbol,
        this.handLot,
        this.orderType,
        leverage
      );
      this.refMoney = result ? result : 0;
    },
    handleSelectHand(item) {
      this.handLot = item;
      this.handleRefMoney();
    },
    // 挂单类型改变
    handleHungOrderTypeChange(index) {
      this.hungOrderType = index;
    },
    // 买入卖出切换
    handleOrderChange(index) {
      this.orderType = index;
      // 计算挂单时价格的默认值
      this.handleHangPrice();
      //止损、止盈默认价格 默认为空
      this.lossPrice = "";
      this.profitPrice = "";
      this.handleRefMoney();
    },
    // 止损止盈类型切换(0价格、1点数)
    handleLossProfitTypeChange(index) {
      this.lossProfitType = index;
      if (index === 0) {
        this.lossPrice = "";
        this.profitPrice = "";
      } else {
        this.handleDefaultStopSurplus(true);
        this.handleDefaultStopLoss(true);
      }
    },
    // 手数加减
    handleNumChange(bool) {
      if (bool) {
        if (this.handLot < this.maxLot) {
          this.handLot = (this.handLot * 1 + 0.01).toFixed(2);
        }
      } else {
        if (this.handLot > this.minLot) {
          this.handLot = (this.handLot * 1 - 0.01).toFixed(2);
        }
      }
      if (this.handLot < this.minLot || this.handLot > this.maxLot) {
        this.ssClass = false;
      } else {
        this.ssClass = true;
      }
      this.handleRefMoney();
    },
    // 止损获取焦点
    handleLossFocus() {
      if (!this.lossPrice) {
        this.handleDefaultStopLoss(true);
      }
    },
    // 挂单情况下价格默认值的计算
    handleHangPrice() {
      if (this.tabType === 2) {
        let point = this.currentSymbol["j"] * this.currentSymbol["p1"] || 0; // 默认的挂单价格浮动差值
        // 挂单
        if (this.hungOrderType === 0) {
          // 限价
          if (this.orderType === 0) {
            // 买入
            this.hungPrice = (this.Ask * 1 - point * 1).toFixed(
              this.currentSymbol.p
            );
          } else {
            // 卖出
            this.hungPrice = (this.Bid * 1 + point * 1).toFixed(
              this.currentSymbol.p
            );
          }
        } else {
          // 止损
          if (this.orderType === 0) {
            // 买入
            this.hungPrice = (this.Ask * 1 + point * 1).toFixed(
              this.currentSymbol.p
            );
          } else {
            // 卖出
            this.hungPrice = (this.Bid * 1 - point * 1).toFixed(
              this.currentSymbol.p
            );
          }
        }
      }
    },
    // 挂单价格加减
    handleHangPriceChange(bool) {
      if (this.hungPrice === 0 || this.hungPrice === "") {
        this.handleHangPrice();
      } else {
        if (this.tabType === 2) {
          if (bool) {
            this.hungPrice = (this.hungPrice * 1 + 0.01).toFixed(
              this.currentSymbol.p
            );
          } else {
            this.hungPrice = (this.hungPrice * 1 - 0.01).toFixed(
              this.currentSymbol.p
            );
          }
        }
      }
    },
    // 止损默认价格计算
    handleDefaultStopLoss() {
      let pointPrice = this.currentSymbol["j"] * this.currentSymbol["p1"] || 0; // 默认的挂单价格浮动差值
      if (this.tabType === 2) {
        // 挂单
        if (this.orderType === 0) {
          // 买入
          if (this.lossProfitType === 0) {
            // 价格
            this.lossPrice = Math.abs(
              this.hungPrice * 1 - pointPrice * 1
            ).toFixed(this.currentSymbol.p);
          } else {
            // 点数
            this.lossPrice = this.currentSymbol["j"] * 1;
          }
        } else {
          // 卖出
          if (this.lossProfitType === 0) {
            // 价格
            this.lossPrice = Math.abs(
              this.hungPrice * 1 + pointPrice * 1
            ).toFixed(this.currentSymbol.p);
          } else {
            // 点数
            this.lossPrice = this.currentSymbol["j"] * 1;
          }
        }
      } else {
        // 市价
        if (this.orderType === 0) {
          // 买入
          if (this.lossProfitType === 0) {
            // 价格
            this.lossPrice = Math.abs(this.Bid * 1 - pointPrice * 1).toFixed(
              this.currentSymbol.p
            );
          } else {
            // 点数
            this.lossPrice = this.currentSymbol["j"] * 1;
          }
        } else {
          // 卖出
          if (this.lossProfitType === 0) {
            // 价格
            this.lossPrice = Math.abs(this.Ask * 1 + pointPrice * 1).toFixed(
              this.currentSymbol.p
            );
          } else {
            // 点数
            this.lossPrice = this.currentSymbol["j"] * 1;
          }
        }
      }
    },
    // 挂单价格输入中
    handleHungPriceInput() {
      if (this.hungPrice === "" || this.hungPrice === 0) {
        this.gdClass = false;
      } else {
        if (this.gdfuhao === 1) {
          // 大于等于
          this.gdClass =
            this.hungPrice - this.hungLimitPrice >= 0 ? true : false;
        } else {
          // 小于等于
          this.gdClass =
            this.hungPrice - this.hungLimitPrice <= 0 ? true : false;
        }
      }
      //控制小数点位数
      this.hungPrice = this.handleNumFloat(this.hungPrice);
    },
    // 手数输入中
    handleNumberInput() {
      if (this.handLot < this.minLot || this.handLot > this.maxLot) {
        this.ssClass = false;
      } else {
        this.ssClass = true;
      }
      //控制小数点位数
      this.handLot = this.handleNumFloat(this.handLot, 2);
      this.handleRefMoney();
    },
    // 手数输入后
    handleNumberBlur() {
      if (this.handLot < this.minLot) {
        this.handLot = this.minLot;
      } else if (this.handLot > this.maxLot) {
        this.handLot = this.maxLot;
      }
      this.handleRefMoney();
    },
    // 止盈输入中
    handleSurPlusInput() {
      //控制小数点位数
      this.profitPrice = this.handleNumFloat(this.profitPrice);
    },
    // 止损输入中
    handleStopLossInput() {
      //控制小数点位数
      this.lossPrice = this.handleNumFloat(this.lossPrice);
    },
    //控制输入的小数点位数
    handleNumFloat(val, digits) {
      if (val.indexOf(".") === -1) {
        return val;
      }

      let valueArr = val.split(".");
      digits = digits || this.currentSymbol.p;
      if (valueArr[1].length < digits) {
        return val;
      }
      valueArr[1] = valueArr[1].substring(0, digits);
      return (valueArr[0] + "." + valueArr[1]) * 1;
    },
    // 止盈默认价格计算
    handleDefaultStopSurplus() {
      let pointPrice = this.currentSymbol["j"] * this.currentSymbol["p1"] || 0; // 默认的挂单价格浮动差值
      if (this.tabType === 2) {
        // 挂单
        if (this.orderType === 0) {
          // 买入
          if (this.lossProfitType === 0) {
            // 价格
            this.profitPrice = (this.hungPrice * 1 + pointPrice * 1).toFixed(
              this.currentSymbol.p
            );
          } else {
            // 点数
            this.profitPrice = this.currentSymbol["j"] * 1;
          }
        } else {
          // 卖出
          if (this.lossProfitType === 0) {
            // 价格
            this.profitPrice = Math.abs(
              this.hungPrice * 1 - pointPrice * 1
            ).toFixed(this.currentSymbol.p);
          } else {
            // 点数
            this.profitPrice = this.currentSymbol["j"] * 1;
          }
        }
      } else {
        // 市价
        if (this.orderType === 0) {
          // 买入
          if (this.lossProfitType === 0) {
            // 价格
            this.profitPrice = (this.Bid * 1 + pointPrice * 1).toFixed(
              this.currentSymbol.p
            );
          } else {
            // 点数
            this.profitPrice = this.currentSymbol["j"] * 1;
          }
        } else {
          // 卖出
          if (this.lossProfitType === 0) {
            // 价格
            this.profitPrice = Math.abs(this.Ask * 1 - pointPrice * 1).toFixed(
              this.currentSymbol.p
            );
          } else {
            // 点数
            this.profitPrice = this.currentSymbol["j"] * 1;
          }
        }
      }
    },
    // 止盈获取焦健
    handleSurPlusFocus() {
      if (!this.profitPrice) {
        this.handleDefaultStopSurplus(true);
      }
    },
    // 止损价格加减
    handleStopLossChange(bool) {
      if (this.lossPrice === "" || this.lossPrice === 0) {
        this.handleDefaultStopLoss(true);
      } else {
        if (this.lossProfitType === 0) {
          // 价格
          if (bool) {
            this.lossPrice = (this.lossPrice * 1 + 0.01).toFixed(
              this.currentSymbol.p
            );
          } else {
            this.lossPrice = Math.abs(this.lossPrice * 1 - 0.01).toFixed(
              this.currentSymbol.p
            );
          }
        } else {
          // 点数
          if (bool) {
            this.lossPrice = this.lossPrice * 1 + 1;
          } else {
            this.lossPrice = this.lossPrice * 1 - 1;
          }
        }
      }
    },
    // 止盈价格加减
    handleStopSurplusChange(bool) {
      if (this.profitPrice === "" || this.profitPrice === 0) {
        this.handleDefaultStopSurplus(true);
      } else {
        if (this.lossProfitType === 0) {
          if (bool) {
            this.profitPrice = (this.profitPrice * 1 + 0.01).toFixed(
              this.currentSymbol.p
            );
          } else {
            this.profitPrice = Math.abs(this.profitPrice * 1 - 0.01).toFixed(
              this.currentSymbol.p
            );
          }
        } else {
          if (bool) {
            this.profitPrice = this.profitPrice * 1 + 1;
          } else {
            this.profitPrice = this.profitPrice * 1 - 1;
          }
        }
      }
    },
    // 最后下单前进行参数组合
    handleSubmitData() {
      let lossPrice = 0; // 止损价格
      let surplusPrice = 0; // 止盈价格
      let price = 0; // 建仓价格
      if (this.tabType === 1) {
        // 市价
        if (this.orderType === 0) {
          // 买入
          price = this.Ask;
          if (this.lossProfitType === 0) {
            // 价格
            surplusPrice = this.profitPrice;
            lossPrice = this.lossPrice;
          } else {
            // 点差
            surplusPrice =
              this.Bid * 1 + this.profitPrice * this.currentSymbol["p1"] * 1;
            lossPrice =
              this.Bid * 1 - this.lossPrice * this.currentSymbol["p1"] * 1;
          }
        } else {
          // 卖出
          price = this.Bid;
          if (this.lossProfitType === 0) {
            // 价格
            surplusPrice = this.profitPrice;
            lossPrice = this.lossPrice;
          } else {
            // 点差
            surplusPrice =
              this.Ask * 1 - this.profitPrice * this.currentSymbol["p1"] * 1;
            lossPrice =
              this.Ask * 1 + this.lossPrice * this.currentSymbol["p1"] * 1;
          }
        }
      } else {
        // 挂单
        price = this.hungPrice;
        if (this.orderType == 0) {
          // 买入
          if (this.lossProfitType == 0) {
            // 价格
            surplusPrice = this.profitPrice;
            lossPrice = this.lossPrice;
          } else {
            // 点差
            surplusPrice =
              this.hungPrice * 1 +
              this.profitPrice * this.currentSymbol["p1"] * 1;
            lossPrice =
              this.hungPrice * 1 -
              this.lossPrice * this.currentSymbol["p1"] * 1;
          }
        } else {
          // 卖出
          if (this.lossProfitType == 0) {
            // 价格
            surplusPrice = this.profitPrice;
            lossPrice = this.lossPrice;
          } else {
            // 点差
            surplusPrice =
              this.hungPrice * 1 -
              this.profitPrice * this.currentSymbol["p1"] * 1;
            lossPrice =
              this.hungPrice * 1 +
              this.lossPrice * this.currentSymbol["p1"] * 1;
          }
        }
      }
      let lpType = this.lossProfitType == 0 ? 1 : 2; //判断根据价格或点数买入卖出
      return {
        type: this.orderType, //类型  0:市价买入   1:市价卖出   2:限价买入  3:限价卖出   4:止损买入   5:止损卖出 必选
        symbol: this.currentSymbol.name, //品种  必选
        price: price, // 当前买入（卖出）价格 必选
        volum: this.handLot, //建仓手数 必选
        lpType: lpType, //止损止盈类型  1:价格  2:点数  默认为1
        takeProfit: surplusPrice
          ? parseFloat(surplusPrice).toFixed(this.currentSymbol.p)
          : "", // 止盈 可选
        stopLoss: lossPrice
          ? parseFloat(lossPrice).toFixed(this.currentSymbol.p)
          : "", // 止损 可选
        deadline: this.validityValue, // 挂单有效期 1:当天  2:本周
      };
    },
    // 提交
    handleSubmit() {
      let valid = false;
      let params = this.handleSubmitData();
      if (!this.zyClass) {
        this.$toast("止盈止损价格设置异常");
        return false;
      } else if (!this.zsClass) {
        this.$toast("止盈止损价格设置异常");
        return false;
      } else if (!this.ssClass) {
        this.$toast("手数设置异常");
        return false;
      } else if (!this.gdClass && this.tabType === 2) {
        this.$toast("挂单价格设置异常");
        return false;
      } else if (!this.hungPrice && this.tabType === 2) {
        this.$toast("挂单价格设置异常");
        return false;
      }
      if (this.tabType === 1) {
        // 市价
        valid =
          this.zyClass &&
          this.zsClass &&
          this.ssClass &&
          this.currentSymbol &&
          this.currentSymbol.name;
      } else {
        // 挂单
        valid =
          this.zyClass &&
          this.zsClass &&
          this.ssClass &&
          this.gdClass &&
          this.currentSymbol &&
          this.currentSymbol.name &&
          this.hungPrice;
        if (this.orderType === 1 && this.hungOrderType === 0) {
          // 卖出限价
          params.type = 3;
        } else if (this.orderType === 1 && this.hungOrderType === 1) {
          // 卖出止损
          params.type = 5;
        } else if (this.orderType === 0 && this.hungOrderType === 0) {
          // 买入限价
          params.type = 2;
        } else if (this.orderType === 0 && this.hungOrderType === 1) {
          // 买入止损
          params.type = 4;
        }
      }
      if (valid) {
        this.setPayLoading(true);
        this.$toast.loading({
          duration: 0,
          overlay: true,
          message: "下单中...",
        });
        this.trader.createOreder(params, this.handleCreateOrderCd);
        //15秒没反应提示超时
        setTimeout(() => {
          if (this.payLoading) {
            this.setPayLoading(false);
            this.$toast.clear();
            this.$toast("提交超时，请重新操作");
          }
        }, 15000);
      }
    },
    //下单成功的回调函数
    handleCreateOrderCd(data) {
      this.setPayLoading(false);
      this.$toast.clear();
      if (data.status == 1) {
        //开仓成功
        this.succInfo = data.data;
        let orderType = data.data.c;
        if (orderType === 0 || orderType === 1) {
          //市价开仓成功，弹窗提示
          this.dialogObj2 = {
            isShow: true,
            showSuccImg: true,
            content: "下单成功",
            confirmText: "继续下单",
            cancelText: "查看持仓",
          };
        } else {
          //挂单成功，提示后跳转交易页面
          this.$toast.success(data.message);
          setTimeout(() => {
            this.$router.push({
              name: "trade",
              query: {
                source: 2,
              },
            });
          }, 2000);
        }
      } else {
        if (data.message.indexOf("余额不足")) {
          this.dialogObj1 = {
            isShow: true,
            title: "余额不足",
            content:
              "您的账户余额不足以进行本次开仓，请进行注资或调 整手数后再重试",
            confirmText: "充值",
            cancelText: "取消",
          };
        } else {
          this.$toast.fail(data.message);
        }
      }
    },
    handleOrderErrorConfirm() {
      //如果是余额不足导致下单失败  跳转充值
      this.dialogObj1 = {
        isShow: false,
      };
      this.$router.push({
        name: "deposit",
      });
    },
    handleOrderErrorCancel() {
      //取消弹框
      this.dialogObj1 = {
        isShow: false,
      };
    },
    handleOrderSuccConfirm() {
      //继续下单
      this.dialogObj2 = {
        isShow: false,
      };
    },
    handleOrderSuccCancel() {
      //查看持仓
      this.$router.push({
        name: "trade",
      });
    },
    // 有效期改变
    handleValidityValueChange(val) {
      this.validityValue = val;
    },
  },
  // mounted() {
  //   console.log("order");
  //   // 参考保证金
  //   this.handleRefMoney();
  //   let groupInfo =
  //     this.symbolGroup.s && this.currentSymbol.g
  //       ? this.symbolGroup.s[this.currentSymbol.g]
  //       : {};
  //   //console.log(groupInfo)
  //   this.minLot = groupInfo.i ? groupInfo.i / 100 : 0.01; //需除100
  //   this.maxLot = groupInfo.a ? groupInfo.a / 100 : 20; //需除100
  //   this.handLot = this.minLot;
  // },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/assets/scss/market/order.scss";
</style>
