<template>
  <div id="app">
    <Left ref="left" />
    <div class="container">
      <Nav ref="nav" />
      <div class="content">
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
import Left from "@/components/left.vue";
import Nav from "@/components/nav.vue";
export default {
  components: {
    Left,
    Nav,
  },
  data() {
    return {
      //isCloseMenu: true,
    };
  },
  created() {
    //根据屏幕宽度计算字体大小
    const html = document.documentElement, //html
      resizeEvt =
        "orientationchange" in window ? "orientationchange" : "resize",
      recalc = () => {
        var hWidth = html.getBoundingClientRect().width;
        if (!hWidth) return;
        if (hWidth <= 430) {
          html.style.fontSize = (hWidth / 750) * 16 + "px";
          this.$store.commit("common/setIsWap", true);
        } else if (hWidth > 430 && hWidth <= 500) {
          html.style.fontSize = (hWidth / 750) * 13 + "px";
          this.$store.commit("common/setIsWap", true);
        } else if (hWidth > 500 && hWidth <= 600) {
          html.style.fontSize = (hWidth / 750) * 10 + "px";
          this.$store.commit("common/setIsWap", true);
        } else if (hWidth > 600 && hWidth <= 750) {
          html.style.fontSize = (hWidth / 750) * 9 + "px";
          this.$store.commit("common/setIsWap", true);
        } else if (hWidth > 750 && hWidth <= 1023) {
          //大于等于767px 小于等于1023px时
          html.style.fontSize = 12 + "px";
          this.$store.commit("common/setIsWap", false);
        } else if (hWidth > 1023 && hWidth <= 1260) {
          //大于等于767px 小于等于1023px时
          html.style.fontSize = 13 + "px";
          this.$store.commit("common/setIsWap", false);
        } else if (hWidth > 1260 && hWidth <= 1440) {
          //大于等于767px 小于等于1023px时
          html.style.fontSize = 14 + "px";
          this.$store.commit("common/setIsWap", false);
        } else {
          html.style.fontSize = 16 + "px";
          this.$store.commit("common/setIsWap", false);
        }
      };
    if (!document.addEventListener) return;
    window.addEventListener(resizeEvt, recalc, false);
    recalc();
    this.initSocket();
    this.initColorSet();
  },
  methods: {
    // handleShowMenu() {
    //   //this.isCloseMenu = false;
    //   this.$store.commit("common/setIsShowMenu", true);
    //   //this.$refs["nav"].handleShowMenu();
    // },
    // handleHideMenu() {
    //   //this.isCloseMenu = true;
    //   this.$store.commit("common/setIsShowMenu", false);
    //   //this.$refs["nav"].handleHideMenu();
    // },
    initSocket() {
      if (this.$store.state.login.token) {
        let params = {
          account: this.$store.state.login.userInfo.account,
          pwd: this.$store.state.login.userInfo.password,
          isInit: 1,
        };
        this.$store.dispatch("socket/initSocket", params);
      } else {
        this.$store.dispatch("socket/initSocket");
      }
    },
    initColorSet() {
      //找到html标签
      var htmlNode = document.documentElement;
      //读取html标签(根标签)的CSS变量的值
      var upColor = htmlNode.style.getPropertyValue("--up-color");
      if (upColor != this.$store.state.common.redColor) {
        htmlNode.style.setProperty(
          "--up-color",
          this.$store.state.common.colorSet.upColor
        );
        htmlNode.style.setProperty(
          "--down-color",
          this.$store.state.common.colorSet.downColor
        );
      }
    },
  },
};
</script>
<style lang="scss">
@import "@/assets/scss/global.scss";
@import "@/assets/scss/style.scss";
</style>
