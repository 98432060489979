<template>
  <transition name="bounce">
    <!-- mode="out-in" @afterEnter="" @afterLeave="" -->
    <div class="h5 dialog-div" v-if="dialogObj.isShow">
      <div class="mask dialog-mask"></div>
      <div class="dialog">
        <img
          v-if="
            (typeof dialogObj.isClose).toLowerCase() == 'boolean' &&
            !dialogObj.isClose
              ? false
              : true
          "
          class="dialog-img"
          src="@/assets/images/common/close-d.png"
          alt=""
          @click="handleCloseDialog"
        />
        <p class="dialog-title" v-if="dialogObj.title">{{ dialogObj.title }}</p>
        <img
          class="dialog-email-img"
          v-if="dialogObj.showEmailImg"
          src="@/assets/images/dialog/email.png"
          alt=""
        />
        <img
          class="dialog-succ-img"
          v-if="dialogObj.showSuccImg"
          src="@/assets/images/dialog/succ.png"
          alt=""
        />
        <img
          class="dialog-bank-img"
          v-if="dialogObj.showBankImg"
          src="@/assets/images/dialog/bank.png"
          alt=""
        />
        <p
          class="dialog-content"
          v-if="dialogObj.content"
          :class="{
            isbold:
              dialogObj.subContent ||
              dialogObj.showBankImg ||
              dialogObj.showSuccImg ||
              dialogObj.showEmailImg,
          }"
        >
          {{ dialogObj.content }}
        </p>
        <p class="dialog-sub-content" v-if="dialogObj.subContent">
          {{ dialogObj.subContent }}
        </p>
        <div class="slot">
          <slot></slot>
        </div>
        <div class="btn-div">
          <button
            class="button cancel-btn"
            v-if="dialogObj.cancelText"
            @click="handleCancelButton"
          >
            {{ dialogObj.cancelText }}
          </button>
          <button
            class="button confirm-btn"
            v-if="dialogObj.confirmText"
            @click="handleConfirmButton"
          >
            {{ dialogObj.confirmText }}
          </button>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "Dialog",
  data() {
    return {};
  },
  props: {
    dialogObj: {
      default: () => {
        return {
          isShow: false,
          title: "",
          content: "",
          confirmText: "确认",
          cancelText: "取消",
          showEmailImg: false, //是否显示图片
          showSuccImg: false, //是否显示图片
          showBankImg: false, //是否显示图片
          subContent: "", //次级内容
          isClose: true,
        };
      },
    },
  },
  methods: {
    handleCancelButton() {
      this.dialogObj.isShow = false;
      this.$emit("cancel");
    },
    handleConfirmButton() {
      this.dialogObj.isShow = false;
      this.$emit("confirm");
    },
    handleCloseDialog() {
      this.dialogObj.isShow = false;
      if (this.dialogObj.cancelText) {
        this.$emit("cancel");
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/assets/scss/common/dialog.scss";
</style>
