let goodsSymbolsList = {
  GOLD: "伦敦金",
  SILVER: "伦敦银",
  US0il: "美国原油",
};

let foreignSymbolsList = {
  NZDUSD: "纽元美元",
  EURUSD: "欧元美元",
  GBPUSD: "英镑美元",
  USDCAD: "美元加元",
  USDCHF: "美元瑞郎",
  USDJPY: "美元日元",
  EURCHF: "欧元瑞郎",
  EURGBP: "欧元英镑",
  EURJPY: "欧元日元",
  EURAUD: "欧元澳元",
  GBPJPY: "英镑日元",
  GBPCHF: "英镑瑞郎",
  GBPAUD: "英镑澳元",
  AUDJPY: "澳元日元",
  AUDNZD: "澳元纽元",
  NZDJPY: "纽元日元",
  CADJPY: "加元日元",
  USDCNH: "美元离岸人民币",
  USDHKD: "美元港币",
};

let indexSymbolsList = {
  US500: "标准普尔指数",
  US30: "道琼斯指数",
  NAS100: "纳斯达克指数",
  HK50: "恒生指数",
  GER30: "德国指数",
  JPN225: "日经指数",
  DollarIndex: "美元指数",
  CHINA300: "中华300",
  CHA50: "中华A50指数",
};
export default {
  foreignSymbolsList,
  goodsSymbolsList,
  indexSymbolsList,
};
