const state = {
  colorSet: localStorage.getItem("colorSet")
    ? JSON.parse(localStorage.getItem("colorSet"))
    : {
        upColor: "#1fba64",
        downColor: "#f2503c",
        text: "绿涨红跌",
      },
  greenColor: "#1fba64",
  redColor: "#f2503c",
  isFastClose: localStorage.getItem("isFastClose")
    ? localStorage.getItem("isFastClose")
    : false,
  menuList: [],
  currentMenu: "",
  navigateTitle: "",
  isWap: false,
  isShowLogin: false, //是否显示登录弹框
  isShowMenu: false, //是否显示菜单
  isShowActivity: false, //是否显示活动
  isShowRegister:false,
};

const mutations = {
  setColorSet(state, data) {
    state.colorSet = data;
  },
  setFastClose(state, data) {
    state.isFastClose = data;
  },
  setMenuList(state, info) {
    state.menuList = info;
  },
  setCurrentMenu(state, data) {
    state.currentMenu = data;
  },
  setNavigateTitle(state, data) {
    state.navigateTitle = data;
  },
  setIsWap(state, data) {
    state.isWap = data;
  },
  setIsShowLogin(state, data) {
    state.isShowLogin = data;
  },
  setIsShowMenu(state, data) {
    state.isShowMenu = data;
  },
  setIsShowActivity(state, data) {
    state.isShowActivity = data;
  },
  setIsShowRegist(state, data) {
    state.isShowRegister = data;
  },
};

const actions = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
