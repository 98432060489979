<template>
  <div class="login forgetpass">
    <!-- <div class="mask" v-if="isShow"></div> -->
    <div class="login-div">
      <img
        src="@/assets/images/login/close.png"
        alt=""
        class="login-close"
        @click="handleCloseForgetPass"
      />
      <h3 class="login-title">忘记密码</h3>
      <div class="form login-form">
        <div class="input">
          <div class="input-content">
            <i class="phone"></i>
            <input
              type="text"
              placeholder="请输入手机号"
              form="rules"
              v-model="forgetPass.phone"
              v-checkParam="rules.phone"
            />
          </div>
        </div>
        <div class="input">
          <div class="input-content">
            <i class="code"></i>
            <input
              type="text"
              placeholder="请输入验证码"
              form="rules"
              v-model="forgetPass.code"
              v-checkParam="rules.code"
            />
            <a class="btn-send" @click="handleSendCode">{{ codeText }}</a>
          </div>
        </div>
        <div class="input">
          <div class="input-content">
            <i class="pass"></i>
            <input
              :type="isShowPass1 ? 'text' : 'password'"
              placeholder="请输入新密码"
              form="rules"
              v-model="forgetPass.password"
              v-checkParam="rules.password"
            />
            <i
              class="eye"
              @click="isShowPass1 = !isShowPass1"
              :class="isShowPass1 ? 'eye-open' : 'eye-close'"
            ></i>
          </div>
        </div>
        <div class="input">
          <div class="input-content">
            <i class="pass"></i>
            <input
              :type="isShowPass2 ? 'text' : 'password'"
              placeholder="请确认密码"
              form="rules"
              v-model="forgetPass.repassword"
              v-checkParam="rules.repassword"
            />
            <i
              class="eye"
              @click="isShowPass2 = !isShowPass2"
              :class="isShowPass2 ? 'eye-open' : 'eye-close'"
            ></i>
          </div>
        </div>
        <p class="agreement">*密码需要6-18个字符，并且包括至少1个字母和数字</p>
        <div class="btn-div center">
          <button
            class="button reg-btn"
            v-checkSubmit="'rules'"
            :submit="handleForgetPass"
          >
            提交
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import login from "@/apis/login.js";
export default {
  name: "ForgetPass",
  props: {
    isShow: Boolean,
    appId: String,
  },
  data() {
    return {
      timer: "", //验证码定时器
      codeText: "获取验证码",
      isShowPass1: false,
      isShowPass2: false,
      forgetPass: {
        phone: "",
        password: "",
        repassword: "",
        code: "",
      },
      rules: {
        phone: [
          { rule: "required", msg: "请输入手机号" },
          { rule: /^1[3-9]\d{9}$/, msg: "请输入正确的手机号" },
        ],
        code: [
          { rule: "required", msg: "请输入验证码" },
          { rule: /(\d{4,6})/, msg: "验证码格式错误" },
        ],
        password: [
          { rule: "required", msg: "密码不能为空" },
          {
            rule: /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,12}$/,
            msg: "密码为6-12位字母加数字",
          },
        ],
        repassword: [
          { rule: "required", msg: "确认密码不能为空" },
          {
            rule: /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,12}$/,
            msg: "密码为6-12位字母加数字",
          },
        ],
      },
    };
  },
  methods: {
    handleCloseForgetPass() {
      this.$emit("closeForgetPass");
    },
    handleForgetPass() {
      if (this.forgetPass.password != this.forgetPass.repassword) {
        this.$toast("新密码和确认密码不一致");
        return;
      }
      const params = {
        mobile: this.forgetPass.phone,
        code: this.forgetPass.code,
        password: this.forgetPass.password,
      };
      this.$toast.loading({
        overlay: true,
        message: "提交中...",
        duration: 0,
      });
      login.submitNewPwd(params).then((res) => {
        this.$toast.clear();
        if (res.IsSuccess) {
          this.$toast.success("恭喜您，密码找回成功");
          setTimeout(() => {
            //隐藏找回密码表单
            this.$emit("closeForgetPass");
            //显示登录表单
            this.$emit("showLogin");
          }, 500);
        } else {
          this.$toast(res.Data);
        }
      });
    },
    handleSendCode() {
      //手机是否填写
      if (
        !this.forgetPass.phone ||
        !/^1[3-9]\d{9}$/.test(this.forgetPass.phone)
      ) {
        this.$toast("请输入正确的手机号");
        return;
      }
      //显示腾讯验证
      var captcha = new window.TencentCaptcha(this.appId, (res) => {
        if (res.ret === 0) {
          // 滑块验证通过---获取验证码--执行倒计时
          this.doSendCode(res);
        } else {
          this.$toast("请先完成验证");
        }
      });
      // 显示验证码
      captcha.show();
    },
    //发送验证码
    doSendCode(data) {
      const params = {
        ticket: data.ticket,
        randstr: data.randstr,
        mobile: this.forgetPass.phone,
        type: 0, //类型（0腾讯云，1阿里云）
      };
      this.$toast.loading({
        overlay: true,
        message: "发送中...",
        duration: 0,
      });
      login.sendSms(params).then((res) => {
        this.$toast.clear();
        if (res.IsSuccess) {
          this.$toast(res.Data);
          this.countNum();
        } else {
          this.$toast(res.Data);
        }
      });
    },
    //发送验证码倒计时
    countNum() {
      clearInterval(this.timer);
      let count = 60;
      let text = "";
      this.timer = setInterval(() => {
        if (count <= 0) {
          count = 0;
          text = "重新获取验证码";
          clearInterval(this.timer);
        } else {
          count -= 1;
          text = count + "秒后重新获取";
        }
        this.codeText = text;
      }, 1000);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/assets/scss/common/login.scss";
</style>
