<template>
  <div class="nav-div">
    <div class="nav" :class="{ 'login-nav': userInfo.account }">
      <div class="nav-left flex-v-center">
        <!-- <router-link :to="{ name: 'index' }"
          ><img
            src="@/assets/images/common/pc/logo.png"
            alt=""
            class="logo is-show-large is-show-medium"
          />
        </router-link> -->
        <!-- <template> -->
        <img
          v-if="isWap"
          src="@/assets/images/common/wap/menu.png"
          alt=""
          class="menu-icon"
          @click="handleShowMenu"
        />
        <!-- <div class="nav-title" v-if="navigateTitle">
            {{ navigateTitle }}
          </div> -->
        <!-- </template> -->
        <div v-else class="login" @click="handleShowLogin">
          <template v-if="!userInfo.account">
            <img src="@/assets/images/common/pc/user.png" alt="" />
            <span class="is-hidden-medium">欢迎登录</span>
          </template>
          <template v-else>
            <img
              v-if="userInfo.avator"
              :src="userInfo.avator"
              alt=""
              class="login-img"
            />
            <img
              v-else
              src="@/assets/images/common/pc/login-user.png"
              class="login-img"
            />
            <div class="login-info">
              <p class="login-type">
                <span>{{ userType == 1 ? "真实" : "模拟" }}</span>
                <img src="@/assets/images/common/pc/eye.png" alt="" />
              </p>
              <p class="login-account">
                {{
                  userType == 1 ? userInfo.account : userInfo.simulatorAccount
                }}
              </p>
            </div>
          </template>
        </div>
      </div>
      <div
        class="nav-mid is-show-large is-show-medium flex-v-center flex-h-around"
        v-if="
          wsLoginInfo && wsLoginInfo.l && userInfo && userInfo.account && !isWap
        "
      >
        <p class="nav-mid-p">
          <span class="num">{{
            wsLoginInfo.b ? wsLoginInfo.b.toFixed(2) : "0.00"
          }}</span>
          <span class="text">余额</span>
        </p>
        <p class="nav-mid-p">
          <span class="num">{{
            isNaN(wsLoginInfo.jz) ? "0.00" : wsLoginInfo.jz.toFixed(2)
          }}</span>
          <span class="text">净值</span>
        </p>
        <p class="nav-mid-p">
          <span class="num">{{
            wsLoginInfo.pr ? wsLoginInfo.pr.toFixed(2) : "0.00"
          }}</span>
          <span class="text">持仓盈亏</span>
        </p>
        <p class="nav-mid-p">
          <span class="num">{{
            isNaN(wsLoginInfo.kybzj) ? "0.00" : wsLoginInfo.kybzj
          }}</span>
          <span class="text">可用保证金</span>
        </p>
        <p class="nav-mid-p">
          <span class="num">{{
            wsLoginInfo.zbzj ? wsLoginInfo.zbzj.toFixed(2) : "0.00"
          }}</span>
          <span class="text">占用保证金</span>
        </p>
        <p class="nav-mid-p">
          <span class="num">{{
            wsLoginInfo.bzjbl && !Number.isNaN(wsLoginInfo.bzjbl)
              ? wsLoginInfo.bzjbl
              : "0.00%"
          }}</span>
          <span class="text">保证金比例</span>
        </p>
      </div>
      <div class="nav-mid flex-v-center flex-h-around" v-if="isWap">
        <a href="/">
          <img
            src="@/assets/images/common/wap/logo.png"
            alt="logo"
            class="nav-min-logo"
          />
        </a>
      </div>
      <div class="nav-right flex-v-center flex-h-between">
        <i class="msg" @click="handleIsShowMsg"></i>
        <i class="serve" @click="handleServe"></i>
      </div>
    </div>
    <transition name="slide-left">
      <div
        class="menu"
        v-show="isShowMenu"
        :class="userInfo && userInfo.account ? 'menu-is-login' : ''"
      >
        <div v-if="isWap" class="menu-top">
          <div class="menu-login" v-if="!userInfo || !userInfo.account">
            <p class="menu-login-p">您好，欢迎登录！</p>
            <p class="menu-login-p">登录后可查看余额</p>
            <div class="menu-login-btn">
              <div
                class="menu-btn login-btn"
                @click="handleShowLogin('hideMenu')"
              >
                登录
              </div>
              <div
                class="menu-btn reg-btn"
                @click="handleShowRegister('hideMenu')"
              >
                注册账户
              </div>
            </div>
          </div>
          <div class="menu-info" v-if="userInfo && userInfo.account">
            <div class="menu-user-info">
              <div class="menu-user-info-left">
                <img
                  v-if="userInfo.avator"
                  :src="userInfo.avator"
                  alt=""
                  class="login-img"
                />
                <img
                  v-else
                  src="@/assets/images/common/pc/login-user.png"
                  class="login-img"
                />
                <div class="login-info">
                  <p class="login-type">
                    <span>{{ userType == 1 ? "真实" : "模拟" }}</span>
                    <img src="@/assets/images/common/pc/eye.png" alt="" />
                  </p>
                  <p class="login-account">
                    {{
                      userType == 1
                        ? userInfo.account
                        : userInfo.simulatorAccount
                    }}
                  </p>
                </div>
              </div>
              <div class="menu-user-info-right">
                <span class="switch" @click="handleSwitch">{{
                  userType == 1 ? "模拟" : "真实"
                }}</span>
              </div>
            </div>
            <div class="menu-trade-info">
              <div class="menu-info-p medium-6 small-6">
                <p class="menu-info-title"><span>余额</span></p>
                <span class="menu-info-num">{{
                  wsLoginInfo.b ? wsLoginInfo.b.toFixed(2) : "0.00"
                }}</span>
              </div>
              <div class="menu-info-p medium-6 small-6">
                <p class="menu-info-title"><span>净值</span></p>
                <span class="menu-info-num">{{
                  isNaN(wsLoginInfo.jz) ? "0.00" : wsLoginInfo.jz.toFixed(2)
                }}</span>
              </div>
              <div class="menu-info-p medium-6 small-6">
                <p class="menu-info-title"><span>持仓盈亏</span></p>
                <span class="menu-info-num">{{
                  wsLoginInfo.pr ? wsLoginInfo.pr.toFixed(2) : "0.00"
                }}</span>
              </div>
              <div class="menu-info-p medium-6 small-6">
                <p class="menu-info-title"><span>可用保证金</span></p>
                <span class="menu-info-num">{{
                  isNaN(wsLoginInfo.kybzj) ? "0.00" : wsLoginInfo.kybzj
                }}</span>
              </div>
              <div class="menu-info-p medium-6 small-6">
                <p class="menu-info-title">
                  <span>占用保证金</span>
                </p>
                <span class="menu-info-num">{{
                  wsLoginInfo.zbzj ? wsLoginInfo.zbzj.toFixed(2) : "0.00"
                }}</span>
              </div>
              <div class="menu-info-p medium-6 small-6">
                <p class="menu-info-title"><span>保证金比例</span></p>
                <span class="menu-info-num">{{
                  wsLoginInfo.bzjbl && !Number.isNaN(wsLoginInfo.bzjbl)
                    ? wsLoginInfo.bzjbl
                    : "0.00%"
                }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="menu-list">
          <template v-for="item in menuList">
            <div
              class="menu-title"
              v-if="
                (item.meta.isShow && !item.meta.showType) ||
                (item.meta.showType == 'wap' && isWap)
              "
              :key="item.name"
              :class="{ active: currentMenu.pathWay == item.meta.pathWay }"
            >
              <router-link
                v-if="!item.children"
                :to="item.path"
                @click.native="handleHideMenu"
                :key="item.name"
              >
                <div class="menu-content" :class="item.meta.icon">
                  <span>{{ item.meta.title }}</span>
                </div>
              </router-link>
              <div
                class="menu-content"
                v-else
                :class="
                  item.children
                    ? item.meta.icon + ' has-child collapse'
                    : item.meta.icon
                "
                @click="handleShowSubMenu"
              >
                <span>{{ item.meta.title }}</span>
              </div>
              <transition name="collapse">
                <div class="h5 sub-menu" v-if="item.children">
                  <template v-for="subItem in item.children">
                    <p
                      v-if="
                        subItem.meta.isShow && subItem.meta.pathWay == '2-1'
                      "
                      class="sub-menu-title"
                      :class="{
                        active: subItem.meta.pathWay == currentMenu.pathWay,
                      }"
                      :key="subItem.name"
                      @click="handleDeposit(subItem.name)"
                    >
                      {{ subItem.meta.title }}
                    </p>
                    <p
                      v-if="
                        subItem.meta.isShow && subItem.meta.pathWay == '2-2'
                      "
                      class="sub-menu-title"
                      :class="{
                        active: subItem.meta.pathWay == currentMenu.pathWay,
                      }"
                      :key="subItem.name"
                      @click="handleWithdraw(subItem.name)"
                    >
                      {{ subItem.meta.title }}
                    </p>
                    <p
                      v-if="
                        subItem.meta.isShow && subItem.meta.pathWay == '2-3'
                      "
                      class="sub-menu-title"
                      :class="{
                        active: subItem.meta.pathWay == currentMenu.pathWay,
                      }"
                      :key="subItem.name"
                      @click="handleFundsDetail(subItem.name)"
                    >
                      {{ subItem.meta.title }}
                    </p>
                    <router-link
                      v-if="
                        subItem.meta.isShow &&
                        subItem.meta.pathWay != '2-1' &&
                        subItem.meta.pathWay != '2-2' &&
                        subItem.meta.pathWay != '2-3'
                      "
                      :to="subItem.path"
                      @click.native="handleHideMenu"
                      :key="subItem.name"
                    >
                      <p
                        class="sub-menu-title"
                        :class="{
                          active: subItem.meta.pathWay == currentMenu.pathWay,
                        }"
                      >
                        {{ subItem.meta.title }}
                      </p>
                    </router-link>
                  </template>
                </div>
              </transition>
            </div>
          </template>
        </div>
        <div
          class="menu-logout button"
          v-if="userInfo.account"
          @click="handleLogout"
        >
          安全退出
        </div>
      </div>
    </transition>
    <div
      class="mask menu-mask"
      :class="isShowMenu ? 'active' : ''"
      @click="handleHideMenu"
    ></div>
    <div
      class="mask"
      v-show="isShowLogin || isShowRegister || isShowForgetPass || isShowMsg"
      @click.self="handleCloseMsg"
    ></div>
    <Login
      v-show="isShowLogin"
      :appId="validateAppId"
      :clientid="clientid"
      @closeLogin="handleCloseLogin"
      @showRegister="handleShowRegister"
      @showForgetPass="handleShowForgetPass"
    />
    <Register
      v-show="isShowRegister"
      :appId="validateAppId"
      :clientid="clientid"
      @closeRegister="handleCloseRegister"
      @showLogin="handleShowLogin"
    />
    <ForgetPass
      v-show="isShowForgetPass"
      :appId="validateAppId"
      @closeForgetPass="handleCloseForgetPass"
      @showLogin="handleShowLogin"
    />
    <Msg
      ref="msg"
      v-show="isShowMsg"
      @closeMsg="handleCloseMsg"
      :account="userInfo.account"
    />
    <Dialog :dialogObj="dialogObj" @confirm="handleDialogConfirm" />
  </div>
</template>

<script>
import Login from "@/components/login.vue";
import Register from "@/components/register.vue";
import ForgetPass from "@/components/forgetPass.vue";
import Msg from "@/components/msg.vue";
import login from "@/apis/login.js";
import { randomWord } from "@/utils/tool.js";
import { menu } from "@/router/router.js";
import { serve } from "@/mixins/serve.js";
import { collapse } from "@/mixins/collapse.js";
import { switchRole } from "@/mixins/switchRole.js";
import Dialog from "@/components/dialog.vue";
import { mapState } from "vuex";
export default {
  name: "Nav",
  mixins: [serve, collapse, switchRole],
  components: {
    Login,
    Register,
    ForgetPass,
    Msg,
    Dialog,
  },
  data() {
    return {
      isShowForgetPass: false, //是否显示忘记密码
      isShowMsg: false, //是否显示消息中心
      validateAppId: "",
      clientid: "",
      menuList: [],
      dialogObj: {},
    };
  },
  computed: {
    ...mapState("common", [
      "isWap",
      "currentMenu",
      "navigateTitle",
      "isShowLogin",
      "isShowMenu",
      "isShowRegister"
    ]),
    ...mapState("login", ["userInfo", "userType"]),
    wsLoginInfo() {
      return this.$store.state.socket.wsLoginInfo;
    },
  },
  methods: {
    handleShowMenu() {
      //this.isShowMenu = true;
      this.$store.commit("common/setIsShowMenu", true);
    },
    handleHideMenu() {
      //this.isShowMenu = false;
      this.$store.commit("common/setIsShowMenu", false);
    },
    handleServe() {
      this.doServe();
    },
    handleShowLogin(val) {
      if (this.userInfo && this.userInfo.account) {
        return;
      }
      if (val == "hideMenu") {
        this.handleHideMenu();
      }
      this.$store.commit("common/setIsShowRegist", false);
      this.$store.commit("common/setIsShowLogin", true);
    },
    handleCloseLogin() {
      this.$store.commit("common/setIsShowLogin", false);
    },
    handleShowRegister(val) {
      if (this.isShowLogin) {
        this.$store.commit("common/setIsShowLogin", false);
      }
      if (val == "hideMenu") {
        this.handleHideMenu();
      }
      this.$store.commit("common/setIsShowRegist", true);
    },
    handleCloseRegister() {
      this.$store.commit("common/setIsShowRegist", false);
    },
    handleShowForgetPass() {
      if (this.isShowLogin) {
        this.$store.commit("common/setIsShowLogin", false);
      }
      this.isShowForgetPass = true;
    },
    handleCloseForgetPass() {
      this.isShowForgetPass = false;
    },
    handleIsShowMsg() {
      //未登录不可查看
      if (!this.userInfo || !this.userInfo.account) {
        this.$store.commit("common/setIsShowLogin", true);
      } else {
        this.isShowMsg = !this.isShowMsg;
        if (this.isShowMsg && this.$refs["msg"].list.length == 0) {
          this.$refs["msg"].infinite("init");
        }
      }
    },
    handleCloseMsg() {
      if (this.isShowMsg) {
        this.$refs["msg"].handleCloseInfo();
        setTimeout(() => {
          //防止详情和列表同时关闭时 详情突然高度下降问题
          this.isShowMsg = !this.isShowMsg;
        }, 300);
      }
    },
    getAppId() {
      login.getValidateAppId().then((res) => {
        if (res.IsSuccess) {
          this.validateAppId = res.Data;
        }
      });
    },
    getRandomWord() {
      let clientid = randomWord();
      localStorage.setItem("clientid", clientid);
      return clientid;
    },
    handleLogout() {
      this.$toast({
        type: "loading",
        message: "正在登出",
      });
      login.logout().then((res) => {
        if (res.IsSuccess) {
          //清除localStorage
          let clientid = localStorage.getItem("clientid");
          localStorage.clear();
          localStorage.setItem("clientid", clientid);
          //隐藏菜单
          this.handleHideMenu();
          //同步store
          this.$store.commit("login/setToken", "");
          this.$store.commit("login/setUserType", "");
          this.$store.commit("login/setUserInfo", {});
          //更新socket链接
          this.$store.dispatch("socket/changeSocket");
          location.reload();
        } else {
          this.$toast(res.Data);
        }
      });
    },
    setMenuList() {
      this.$store.commit("common/setMenuList", menu);
      this.menuList = menu;
    },
    handleCurrentMenu() {
      this.handleHideMenu();
    },
    handleShowSubMenu($e) {
      this.handleCollapse($e);
    },
    async handleWithdraw(name) {
      if (!this.handleIsLogin()) {
        return;
      }
      //经过测试  通过store返回获取信息不及时，所以这里根据返回重新赋值
      let info = this.userInfo;
      //获取并更新用户状态再次判断
      if (
        this.userInfo.authenticationMethod != 2 ||
        this.userInfo.isBankStatus != 2
      ) {
        info = await this.handleGetInfo();
      }
      if (info.authenticationMethod == 2) {
        //判断是否绑定银行卡
        if (info.isBankStatus != 2) {
          this.dialogObj = {
            isShow: true,
            title: "温馨提示",
            content: "为保证您的资金到账情况，请您先绑定您的银行卡。",
            confirmText: "绑定银行卡",
          };
        } else {
          if (this.userType != 1) {
            this.dialogObj = {
              isShow: true,
              title: "温馨提示",
              content: "请先切换真实账号登录",
              confirmText: "确定",
            };
          } else {
            this.$router.push({
              name: name,
            });
          }
        }
      } else {
        this.dialogObj = {
          isShow: true,
          title: "温馨提示",
          content: "为保证您的资金安全，请您先完成实名认证，感谢您的配合。",
          confirmText: "实名认证",
        };
      }
      this.handleHideMenu();
    },
    async handleDeposit(name) {
      if (!this.handleIsLogin()) {
        return;
      }
      //经过测试  通过store返回获取信息不及时，所以这里根据返回重新赋值
      let info = this.userInfo;
      //获取并更新用户状态再次判断
      if (this.userInfo.authenticationMethod == 0) {
        info = await this.handleGetInfo();
      }
      if (info.authenticationMethod == 0) {
        this.dialogObj = {
          isShow: true,
          title: "温馨提示",
          content: "为保证您的资金安全，请您先完成基础认证，感谢您的配合。",
          confirmText: "基础认证",
        };
        this.handleHideMenu();
        return;
      }
      if (this.userType == 1) {
        this.$router.push({
          name: name,
        });
      } else {
        this.dialogObj = {
          isShow: true,
          title: "温馨提示",
          content: "请联系客服",
          confirmText: "联系客服",
          cancelText: "取消",
        };
      }
      this.handleHideMenu();
    },
    //获取并更新用户信息
    async handleGetInfo() {
      //更新用户信息
      let info = await this.$store.dispatch("login/getUserInfo", {
        password: this.$store.state.login.userInfo.password,
      });
      return info;
    },
    handleFundsDetail(name) {
      if (!this.handleIsLogin()) {
        return;
      }
      if (this.userType != 1) {
        this.dialogObj = {
          isShow: true,
          title: "温馨提示",
          content: "请先切换真实账号",
          confirmText: "确定",
        };
      } else {
        this.$router.push({
          name: name,
        });
      }
      this.handleHideMenu();
    },
    handleIsLogin() {
      if (!this.userInfo || !this.userInfo.account) {
        // this.$toast({
        //   type: "fail",
        //   overlay: true,
        //   message: "请先登录",
        //   duration: 1000,
        // });
        this.$store.commit("common/setIsShowMenu", false);
        this.$store.commit("common/setIsShowLogin", true);
        return false;
      } else {
        return true;
      }
    },
    handleDialogConfirm() {
      if (this.dialogObj.confirmText == "联系客服") {
        this.doServe();
      } else if (this.dialogObj.confirmText == "确定") {
        this.dialogObj = {
          isShow: false,
        };
      } else if (this.dialogObj.confirmText == "绑定银行卡") {
        this.$router.push({
          name: "bank",
        });
      } else {
        this.$router.push({
          name: "account",
        });
      }
    },
  },
  mounted() {
    //获取菜单
    this.setMenuList();
    //获取Appid
    this.getAppId();
    this.clientid = localStorage.getItem("clientid")
      ? localStorage.getItem("clientid")
      : this.getRandomWord();
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/common/nav.scss";
</style>
