export const switchRole = {
  methods: {
    //切换角色
    handleSwitch() {
      //token不变 仅更换账号和socket
      let oldUserType = localStorage.getItem("userType")
        ? localStorage.getItem("userType")
        : 1;
      let newUserType = oldUserType == 1 ? 0 : 1;
      localStorage.setItem("userType", newUserType); //真实or模拟用户 1真实 0模拟
      this.$store.commit("login/setUserType", newUserType);
      //更换socket
      this.$store.dispatch("socket/changeSocket", {
        account:
          newUserType == 1
            ? this.$store.state.login.userInfo.account
            : this.$store.state.login.userInfo.simulatorAccount,
        pwd: this.$store.state.login.userInfo.password,
      });
      //如果切换为模拟账号，且在资金管理模块 跳转首页
      if (newUserType != 1) {
        if (
          this.currentMenu.name == "withdraw" ||
          this.currentMenu.name == "fundsDetails" ||
          this.currentMenu.name == "deposit"
        ) {
          this.$router.push({
            name: "index",
          });
        }
      }
    },
  },
};
