import config from "@/config/index.js";
export const serve = {
  data() {
    return {
      serverUrl: config.BASE_LIVE800_URL, //"https://www.jf24k.hk/common/live800.html",
    };
  },
  computed: {
    isWap() {
      return this.$store.state.common.isWap;
    },
  },
  methods: {
    doServe() {
      //根据屏幕大小 调用不同的打开方式
      if (this.isWap) {
        location.href = this.serverUrl;
      } else {
        this.handleNewOpen();
      }
    },
    handleNewOpen() {
      const h = Math.round((window.innerHeight - 500) / 2);
      const w = Math.round((window.innerWidth - 500) / 2);
      window.open(
        this.serverUrl,
        "newWindow",
        `top=${h},left=${w},menubar=0,scrollbars=1, resizable=1,status=1,titlebar=0,toolbar=0,location=1, width=800, height=550`
      );
    },
  },
};
