<template>
  <div class="info-item">
    <!-- <div class="item-content">
      <p class="rule-title">交易品种</p>
      <div class="symbol-bg symbol-gold">
        伦敦金是由伦敦国际金融期货交易所提供的一种交易产品，是一种黄金交易方式的名称，亦称现货黄金。伦敦金产品简易，门槛较低，全球通行，买涨买跌皆可获利，24小时运作，网上电子交易快捷又安全。
      </div>
      <div class="symbol-bg symbol-silver">
        伦敦银是一种贵金属保证金交易方式，又叫现货白银，与伦敦金一样，伦敦银也是一种24小时买卖的产品，交易规则简单，T+0操作，资金结算时间短。全球的主要市场包括：伦敦、苏黎世、纽约、芝加哥及香港等地。
      </div>
    </div> -->
    <div class="item-content">
      <!-- <p class="rule-title">交易细则</p> -->
      <div class="rule-content">
        <p class="rule-item">
          <span class="rule-item-symbol">合约单位</span>
          <span class="rule-item-symbol">{{ currentSymbol.z }}</span>
        </p>
        <p class="rule-item">
          <span class="rule-item-symbol">货币单位</span>
          <span class="rule-item-symbol">{{ currentSymbol.c }}</span>
        </p>
        <p class="rule-item">
          <span class="rule-item-symbol">报价小数点</span>
          <span class="rule-item-symbol">{{ currentSymbol.p }}</span>
        </p>
        <p class="rule-item">
          <span class="rule-item-symbol">点差</span>
          <span class="rule-item-symbol">{{
            currentSymbol.y ? currentSymbol.y : "浮动"
          }}</span>
        </p>
        <p class="rule-item">
          <span class="rule-item-symbol">单笔交易手数</span>
          <span class="rule-item-symbol" v-if="userInfo && userInfo.account">
            {{ groupInfo && groupInfo.i ? groupInfo.i / 100 : "" }}手~
            {{ groupInfo && groupInfo.a ? groupInfo.a / 100 : "" }}手
          </span>
          <span class="rule-item-symbol" v-else>--</span>
        </p>
        <p class="rule-item">
          <span class="rule-item-symbol">手数差值</span>
          <span class="rule-item-symbol" v-if="userInfo && userInfo.account"
            >{{ groupInfo && groupInfo.p ? groupInfo.p / 100 : "" }}手</span
          >
          <span class="rule-item-symbol" v-else>--</span>
        </p>
        <p class="rule-item">
          <span class="rule-item-symbol">最小挂单距离</span>
          <span class="rule-item-symbol">{{ currentSymbol.j }}</span>
        </p>
        <p class="rule-item">
          <span class="rule-item-symbol">初始保证金</span>
          <span class="rule-item-symbol"
            >{{ currentSymbol.i ? currentSymbol.i / 100 : "" }}美元/手</span
          >
        </p>
        <p class="rule-item">
          <span class="rule-item-symbol">假期保证金</span>
          <span class="rule-item-symbol"
            >{{ currentSymbol.i ? currentSymbol.i / 50 : "" }}美元/手</span
          >
        </p>
        <p class="rule-item">
          <span class="rule-item-symbol">锁仓保证金</span>
          <span class="rule-item-symbol"
            >{{ currentSymbol.h ? currentSymbol.h / 100 : "" }}美元/手</span
          >
        </p>
        <p class="rule-item">
          <span class="rule-item-symbol">隔夜利息（买/卖）</span>
          <span class="rule-item-symbol"
            >{{ currentSymbol.lo }}%~{{ currentSymbol.sh }}%</span
          >
        </p>
        <p class="rule-item">
          <span class="rule-item-symbol">三天利息日</span>
          <span class="rule-item-symbol">{{ interestDate }}</span>
        </p>
        <div class="rule-item">
          <span class="rule-item-symbol"
            >交易时间<br />GMT+{{ $store.state.socket.timeZone.z }}</span
          >
          <div class="rule-item-symbol">
            <p
              class="trade-time"
              v-for="(item, index) in tradeTime"
              :key="index"
            >
              <template v-if="item.length > 0">
                <span class="week">{{ index }}：</span>
                <span
                  class="time"
                  v-for="(items, indexs) in item"
                  :key="indexs"
                >
                  {{ items }}<br />
                </span>
              </template>
            </p>
          </div>
        </div>
        <p class="rule-item">
          <span class="rule-item-symbol">结算时间</span>
          <span class="rule-item-symbol"
            >05:00:00（夏令）<br />06:00:00（冬令）</span
          >
        </p>
      </div>
      <div class="rule-tips">
        <p class="rule-tips-title">备注：</p>
        <p class="rule-tips-p">
          价差：可能因市场的剧烈变化而随之变化。价差：可能因市场的剧烈变化
          而随之变化。价差：可能因市场的剧烈变化而随之变化。
        </p>
      </div>
    </div>
    <!-- <div class="item-content">
      <p class="rule-title">交易时间</p>
      <div class="rule-content">
        <p class="rule-item item-title">
          <span class="rule-item-time">产品</span>
          <span class="rule-item-time">交易时间</span>
          <span class="rule-item-time">备注</span>
        </p>
        <p class="rule-item">
          <span class="rule-item-time">合约单位</span>
          <span class="rule-item-time"></span>
          <span class="rule-item-time"></span>
        </p>
        <p class="rule-item">
          <span class="rule-item-time">报价单位</span>
          <span class="rule-item-time"></span>
          <span class="rule-item-time"></span>
        </p>
      </div>
    </div> -->
  </div>
</template>

<script>
export default {
  name: "MarketDetailData",
  props: {
    currentSymbol: Object,
  },
  data() {
    return {};
  },
  computed: {
    groupInfo() {
      let symbolGroup = this.$store.state.socket.symbolGroup;
      return symbolGroup && symbolGroup.s && this.currentSymbol.g
        ? symbolGroup.s[this.currentSymbol.g]
        : {}; // 当前选中品种的分组信息
    },
    interestDate() {
      if (this.currentSymbol.sr) {
        return this.formatWeek(this.currentSymbol.sr);
      }
      return "";
    },
    tradeTime() {
      if (!this.currentSymbol.t1) {
        return;
      }
      let timeArr = this.currentSymbol.t1;
      let tradeTime = {};
      timeArr.forEach((item, index) => {
        let week = this.formatWeek(index);
        tradeTime[week] = [];
        item.forEach((items) => {
          if (Object.keys(items).length > 0) {
            tradeTime[week].push(this.formatTime(items));
          }
        });
      });
      //console.log(tradeTime);
      return tradeTime;
    },
    userInfo() {
      return this.$store.state.login.token
        ? this.$store.state.login.userInfo
        : {};
    },
  },
  mounted() {
    //this.pageInit();
  },
  methods: {
    // async pageInit() {
    //   let groupInfo =
    //     this.symbolGroup.s && this.currentSymbol.g
    //       ? this.symbolGroup.s[this.currentSymbol.g]
    //       : {}; // 当前选中品种的分组信息
    //   console.log(this.symbolGroup);
    //   console.log(this.currentSymbol);
    //   this.symbolInfo.minLot =
    //     groupInfo && groupInfo.i ? groupInfo.i / 100 : ""; //最小手数 需除100
    //   this.symbolInfo.maxLot =
    //     groupInfo && groupInfo.a ? groupInfo.a / 100 : ""; //最大手数 需除100
    //   this.symbolInfo.handStep =
    //     groupInfo && groupInfo.p ? groupInfo.p / 100 : ""; //手数差值 需除100
    //   //三天利息日
    //   this.symbolInfo.interestDate = this.formatWeek(this.currentSymbol.sr);
    //   //交易时间
    //   this.symbolInfo.tradeTime = this.handleTradeTime();
    // },
    // handleTradeTime() {
    //   if (!this.currentSymbol.t1) {
    //     return;
    //   }
    //   let timeArr = this.currentSymbol.t1;
    //   let tradeTime = {};
    //   timeArr.forEach((item, index) => {
    //     let week = this.formatWeek(index);
    //     tradeTime[week] = [];
    //     item.forEach((items) => {
    //       if (Object.keys(items).length > 0) {
    //         tradeTime[week].push(this.formatTime(items));
    //       }
    //     });
    //   });
    //   //console.log(tradeTime);
    //   return tradeTime;
    // },
    //获取时间格式
    formatTime(items) {
      let openTime = this.formatNum(items.h) + ":" + this.formatNum(items.m); //开盘时间（时+分）
      let closeTime = this.formatNum(items.a) + ":" + this.formatNum(items.b); //闭盘时间（时+分）
      return openTime + "-" + closeTime;
    },
    //获取数字格式
    formatNum(val) {
      if (val) {
        return val > 9 ? val : "0" + val;
      } else {
        return "00";
      }
    },
    //获取周几
    formatWeek(index) {
      let weekDate = ["周日", "周一", "周二", "周三", "周四", "周五", "周六"];
      return weekDate[index];
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/assets/scss/market/rule.scss";
</style>
