import Vue from "vue";
import VueRouter from "vue-router";
import { menu, otherRouters } from "./router";
import store from "../store";
import { Toast } from "vant";

Vue.use(VueRouter);

const router = new VueRouter({
  //mode: "history",
  base: process.env.BASE_URL,
  routes: [...menu, ...otherRouters],
});

VueRouter.prototype.goBack = function () {
  this.isBack = true;
  window.history.go(-1);
};

//路由器跳转前置操作
router.beforeEach(async (to, from, next) => {
  // 根据用户role判断路由权限
  // 未登录跳转和登录后不进入登录页
  // 允许登录页跳转到密码修改页面
  if (to.name == "index" || to.name == "calendar" || to.name == "activity") {
    //进入首页,日历 不做限制
    next();
  } else if (
    store.state.login.userInfo &&
    store.state.login.userInfo.account &&
    store.state.login.token
  ) {
    //进入其他页面 判断是否已登录
    if (store.state.login.userType == 1) {
      //真实账户
      next();
    } else {
      if (to.name == "withdraw" || to.name == "fundsDetails") {
        //如果是进入取款，资金流水  提示使用真实账户
        Toast({
          type: "fail",
          overlay: true,
          message: "请先切换真实账号",
          duration: 1000,
        });
        return;
      } else if (to.name == "deposit") {
        //如果是进入充值 提示联系客服
        Toast({
          type: "fail",
          overlay: true,
          message: "请联系客服",
          duration: 1000,
        });
        return;
      } else {
        next();
      }
    }
  } else {
    //进入其他页面 未已登录
    // Toast({
    //   type: "fail",
    //   overlay: true,
    //   message: "请先登录",
    //   duration: 1000,
    // });
    store.commit("common/setIsShowLogin", true);
    next({
      name: "index",
      replace: true,
    });
  }
});

//路由器跳转后置操作, from, next
router.afterEach((to) => {
  to.meta.title ? (document.title = to.meta.title) : null;
  store.commit("common/setCurrentMenu", {
    name: to.name,
    pathWay: to.meta.pathWay,
  });
  store.commit("common/setNavigateTitle", to.meta.title);
});
export default router;
