let config;
if (
  process.env.NODE_ENV == "production" &&
  location.href.indexOf(".lan") === -1 &&
  location.href.indexOf("192.168") === -1 &&
  location.href.indexOf("localhost") === -1
) {
  //正式机
  let domain = location.host.replace(
    /^www\.|^m\.|^h5\.|^account\.|^maccount\.|^uc\.|^muc\./,
    "."
  ); //'.jf24k.hk';
  config = {
    BASE_ACCOUNT_URL: "https://maccount" + domain + "/", //开户站点链接配置 访问协议用
    BASE_LIVE800_URL: "https://www" + domain + "/common/live800.html",
    BASE_ANTSWALLETS_URL: "https://upay.antswallets.com/", //蚂蚁钱包地址
  };
} else {
  config = {
    BASE_ACCOUNT_URL: "http://maccount.jf24k.lan/", //开户站点链接配置
    BASE_LIVE800_URL: "https://www.jf24k.hk/common/live800.html",
    BASE_ANTSWALLETS_URL: "http://upay.antswallets.lan/", //蚂蚁钱包地址
  };
}
export default config;
